import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

import * as Sentry from '@sentry/browser';
import { Integrations } from "@sentry/tracing";
import {SENTRY_JS_DSN, SENTRY_JS_ENV} from './constants';


if (SENTRY_JS_DSN && SENTRY_JS_ENV) {
  Sentry.init({
    dsn: SENTRY_JS_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: SENTRY_JS_ENV,
  });
}

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
