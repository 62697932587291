import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell, TableFooter,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import {exportFile} from "src/api";
import {Tooltip} from 'src/components';
import {Download} from "react-feather";


const DeviceSummary = ({ className, devices, startDate, endDate, ...rest }) => {
  const exportDeviceSummary = () => {
    exportFile("tickets/statistics/export/devices", {startDate, endDate})
  }
  return (
    <Card {...rest}>
      <CardHeader
        title="Podsumowanie modeli urządzeń"
        action={
          <IconButton aria-label="settings">
              <Tooltip title="Eksport">
                <Download onClick={exportDeviceSummary} />
              </Tooltip>
          </IconButton>
        }
      />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            {devices.header.map((title, idx) => (
              <TableCell key={`device-header-${idx}`}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.data.map((row, idx) => (
            <TableRow
              hover
              key={`device-row-${idx}`}
            >
              {row.map((cell, jdx) => (
                <TableCell key={`device-cell-${idx}-${jdx}`}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
            <TableRow>
              {devices.footer.map((cell, idx) => (
                <TableCell key={`device-footer-${idx}`}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
        </TableFooter>
      </Table>
    </Card>
  );
};

DeviceSummary.propTypes = {
  className: PropTypes.string
};

export default DeviceSummary;
