import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import Page from 'src/components/Page';
import {api} from 'src/api';
import {Plus, Search} from 'react-feather';
import {Loader} from 'src/components';
import Results from "./Results";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  projectCard: {
    height: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  onlyUnreadFormControl: {
    margin: theme.spacing(1),
    width: '100%',
    paddingTop: '6px',
  }
}));

const ProjectList = () => {
  const classes = useStyles();
  const [tickets, setTickets] = useState([]);
  const [searchParams, _] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [ticketStatusFilter, setTicketStatusFilter] = useState(JSON.parse(localStorage.getItem("ticketStatusFilter")));
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(JSON.parse(localStorage.getItem("categoryFilter")));
  const [onlyUnread, setOnlyUnread] = useState(JSON.parse(localStorage.getItem("onlyUnread")));

  const fetchTicketStatuses = () => {
    api.get('ticketstatuses/').then(res => {
      setTicketStatuses(res.data);
    });
  }

  useEffect(() => {
    setSearchQuery((searchParams.get('q') || '').toLowerCase())
  }, [searchParams]);

  useEffect(fetchTicketStatuses, []);

  const fetchCategories = () => {
    api.get('categories/').then(res => {
      setCategories(res.data);
    });
  }

  useEffect(fetchCategories, []);

  const fetchTickets = () => {
    api.get('user_tickets/').then(res => {
      setTickets(res.data);
      setIsLoading(false);
    });
  }

  useEffect(fetchTickets, []);

  useEffect(() => {
    localStorage.setItem('ticketStatusFilter', JSON.stringify(ticketStatusFilter));
  }, [ticketStatusFilter]);

  useEffect(() => {
    localStorage.setItem('categoryFilter', JSON.stringify(categoryFilter));
  }, [categoryFilter]);

  useEffect(() => {
    localStorage.setItem('onlyUnread', JSON.stringify(onlyUnread));
  }, [onlyUnread]);

  const search = (ticket) => {
    const values = [
      ticket.serial_no,
      ticket.category?.name,
      ticket.device?.name,
      ticket.defect?.name,
      ticket.applicant?.first_name,
      ticket.applicant?.last_name,
      ticket.applicant?.email,
      ticket.applicant?.phone,
    ];

    let ok = false
    for (const value of values) {
      if (value?.toLowerCase().includes(searchQuery)) {
        ok = true;
      }
    }

    if (ticketStatusFilter) {
      ok = ok && ticketStatusFilter === ticket?.status?.id;
    }

    if (categoryFilter) {
      ok = ok && categoryFilter === ticket?.category?.id;
    }

    if (onlyUnread) {
        ok = ok && ticket?.is_unread;
    }

    return ok;
  }

  return (
    <Page
      className={classes.root}
      title="Zgłoszenia"
    >
      <Container maxWidth={false}>
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            color="primary"
            startIcon={<Plus />}
            variant="contained"
            component={Link} to="/ticket"
          >
            Nowe zgłoszenie
          </Button>
        </Box>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box mr={2}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={4}
                  >
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon
                                color="action"
                                fontSize="small"
                              >
                                <Search />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value?.toLowerCase())}
                        placeholder="Wyszukaj zgłoszenie"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <FormControl
                      className={classes.formControl}
                    >
                      <TextField
                        label="Status"
                        labelId="ticketStatusesFilter"
                        name="ticketStatusesFilter"
                        onChange={(e) => setTicketStatusFilter(e.target.value)}
                        value={ticketStatusFilter}
                        variant="outlined"
                        select
                      >
                        <MenuItem key="empty_ticket_status" value="">---</MenuItem>
                        {ticketStatuses.map(ts =>
                          <MenuItem key={ts.id} value={ts.id}>
                            {ts.status}
                          </MenuItem>
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <FormControl
                      className={classes.formControl}
                    >
                      <TextField
                        label="Kategoria"
                        labelId="ticketCategoriesFilter"
                        name="ticketCategoriesFilter"
                        onChange={(e) => setCategoryFilter(e.target.value)}
                        value={categoryFilter}
                        variant="outlined"
                        select
                      >
                        <MenuItem key="empty_ticket_status" value="">---</MenuItem>
                        {categories.map(category =>
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                  >
                    <FormControl
                      className={classes.onlyUnreadFormControl}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            onlyUnread onChange={(e) => setOnlyUnread(e.target.checked)}
                            checked={onlyUnread}
                            name="onlyUnread"
                            color="primary"
                          />
                        }
                        label="Tylko nieprzeczytane"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt={3}>
          {isLoading && <Loader/>}
          {!isLoading &&
            <Results
              fetchTickets={fetchTickets}
              tickets={tickets.filter(search)}
              ticketStatuses={ticketStatuses}
            />
          }
        </Box>
      </Container>
    </Page>
  );
};

export default ProjectList;
