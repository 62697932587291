import React from "react";
import {
    Button,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {Tooltip} from "../../components";
import {Link} from "react-router-dom";
import {Warning} from "@material-ui/icons";
import moment from "moment/moment";


const useStyles = makeStyles((theme) => ({
    alignTop: {
        verticalAlign: "top"
    },
    noPadding: {
        padding: 0,
    }
}));


const TicketTable = ({ ticket, serialNoCell }) => {
    const classes = useStyles();


    const defaultSerialNoCell =
        <TableCell>
            <Grid
                container
                key={`serial-no-cell-${ticket.id}`}
                spacing={0}
            >
                <Grid item xs={6}>
                    {ticket.serial_no}
                </Grid>
                <Grid item xs={2}>
                    {
                        ticket?.has_serial_no_duplicates &&
                        <Tooltip
                            title={"Dla tego numeru seryjnego istnieje już inne zgłoszenie"}
                        >
                            <Button
                                className={classes.noPadding}
                                variant="text"
                                component={Link}
                                to={`/app/tickets?q=${ticket.serial_no}`}
                            >
                                <Warning/>
                            </Button>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
        </TableCell>

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Numer zgłoszenia
                    </TableCell>
                    <TableCell>
                        Kategoria
                    </TableCell>
                    <TableCell>
                        Model
                    </TableCell>
                    <TableCell>
                        Usterka
                    </TableCell>
                    <TableCell style={{width: "25%"}}>
                        Nr seryjny urządzenia
                    </TableCell>
                    <TableCell>
                        Data utworzenia
                    </TableCell>
                    <TableCell>
                        Data ostatniej modyfikacji
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.alignTop}>
                    <TableCell>
                        {ticket?.number}
                    </TableCell>
                    <TableCell>
                        {ticket?.category.name}
                    </TableCell>
                    <TableCell>
                        {ticket?.device?.name}
                    </TableCell>
                    <TableCell>
                        {ticket?.defect?.name}
                        <br/>
                        <Typography variant="caption">
                            {ticket?.defect_description}
                        </Typography>
                    </TableCell>
                    {serialNoCell ? serialNoCell : defaultSerialNoCell}
                    <TableCell>
                        {moment(ticket?.created_on).format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                        {moment(ticket?.updated_on).format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default TicketTable;
