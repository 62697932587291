import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    makeStyles,
    MenuItem,
    OutlinedInput,
    TextField,
    Checkbox,
    Typography, FormControlLabel,
} from '@material-ui/core';
import Page from 'src/components/Page';
import {api} from 'src/api';
import {Loader} from 'src/components';
import {useParams} from "react-router-dom";
import UsedPartsTable from "../ProjectListView/UsedPartsTable";
import {Check, X} from "react-feather";
import Alert from "@material-ui/lab/Alert";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    spacer: {
        flexGrow: 1,
    },
    whiteButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#FFFFFF',
    },
    img: {
        textAlign: 'center',
    },
    textTop: {
        verticalAlign: "top"
    }
}));

const TicketDetailsView = () => {
    const {ticketId} = useParams();
    const classes = useStyles();
    const [ticket, setTicket] = useState(null);
    const [usedParts, setUsedParts] = useState([]);
    const [isLoadingTicket, setIsLoadingTicket] = useState(true);
    const [isLoadingApplicant, setIsLoadingApplicant] = useState(true);
    const [isLoadingParts, setIsLoadingParts] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [wantInvoice, setWantInvoice] = useState(false);
    const [invoiceFullname, setInvoiceFullname] = useState("");
    const [invoiceCompany, setInvoiceCompany] = useState("");
    const [invoiceNip, setInvoiceNip] = useState("");
    const [invoiceAddress, setInvoiceAddress] = useState("");
    const [invoicePostcode, setInvoicePostcode] = useState("");
    const [invoiceCity, setInvoiceCity] = useState("");
    const disabledForm = ticket?.is_quotation_accepted !== null


    const fetchTicket = () => {
        api.get(`tickets/${ticketId}/`).then(res => {
            setTicket(res.data);
            setPaymentMethod(res.data.payment_method)
            setIsLoadingTicket(false);
        });
    }

    const fetchApplicant = () => {
        api.get(`ticket_applicants/${ticket?.applicant.id}/`).then(res => {
            setWantInvoice(res.data.want_invoice);
            setInvoiceFullname(res.data.invoice_fullname);
            setInvoiceCompany(res.data.invoice_company);
            setInvoiceNip(res.data.invoice_nip);
            setInvoiceAddress(res.data.invoice_address);
            setInvoicePostcode(res.data.invoice_postcode);
            setInvoiceCity(res.data.invoice_city);
            setIsLoadingApplicant(false);
        });
    }
    useEffect(fetchApplicant, [ticket, ticketId]);

    const updateQuotation = (is_quotation_accepted, payment_method) => {
        if (is_quotation_accepted === true) {
            api.patch(`ticket_applicants/${ticket.applicant.id}/`, {
                want_invoice: wantInvoice,
                invoice_fullname: invoiceFullname,
                invoice_company: invoiceCompany,
                invoice_nip: invoiceNip,
                invoice_address: invoiceAddress,
                invoice_postcode: invoicePostcode,
                invoice_city: invoiceCity
            }).then(() => {
                fetchApplicant();
                updateTicket(is_quotation_accepted, payment_method);
            });
        } else {
            updateTicket(is_quotation_accepted, payment_method);
        }
        window.scrollTo(0, 0);
    }

    const updateTicket = (is_quotation_accepted, payment_method) => {
        const data = {
            is_quotation_accepted: is_quotation_accepted,
            payment_method: payment_method
        };
        api.patch(`tickets/${ticketId}/`, data).then(
            fetchTicket
        );
    }

    useEffect(fetchTicket, [ticketId]);

    const fetchParts = () => {
        api.get(`parts/?ticket=${ticketId}&device=${ticket?.category?.id}`).then(res => {
            setIsLoadingParts(false);
        });
    }

    useEffect(fetchParts, [ticket, ticketId]);

    const fetchUsedParts = () => {
        api.get(`ticket_parts/?ticket=${ticketId}`).then(res => {
            setUsedParts(res.data);
            setIsLoadingParts(false);
        });
    }

    useEffect(fetchUsedParts, [ticketId]);

    return (
        <Page
            className={classes.root}
            title="Raport"
        >
            <Container maxWidth={false}>
                {
                    ticket?.is_quotation_accepted === true &&
                    ticket?.service_type === "paid" &&
                    <Alert severity="success">
                        Wycena została zaakceptowana przez zgłaszającego.
                        {ticket?.payment_method === "online" &&
                            "Link do opłacenia zamówienia zostanie wysłany w wiadomości mailowej."
                        }
                    </Alert>
                }
                {
                    ticket?.is_quotation_accepted === false &&
                    ticket?.service_type === "paid" &&
                    <Alert severity="error">Wycena została odrzucona przez zgłaszającego</Alert>
                }
                {
                    ticket?.is_quotation_accepted === null &&
                    ticket?.service_type === "paid" &&
                    <Alert severity="info">Należy wybrać metodę płatności i zaakceptować lub odrzucić wycenę</Alert>
                }
                <Box>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Raport
                            </Typography>
                            {isLoadingTicket && <Loader/>}
                            {
                                !isLoadingTicket &&
                                <>
                                    <Box p={1}>
                                        <TextField
                                            disabled
                                            key="serviceType"
                                            fullWidth
                                            select
                                            name="serviceType"
                                            label="Typ usługi"
                                            input={<OutlinedInput label="Typ usługi"/>}
                                            value={ticket?.service_type}
                                        >
                                            <MenuItem value={"warranty"}>{"gwarancyjna"}</MenuItem>
                                            <MenuItem value={"paid"}>{"płatna"}</MenuItem>
                                        </TextField>
                                    </Box>
                                    <Box p={1}>
                                        <TextField
                                            disabled
                                            key="repairDuration"
                                            fullWidth
                                            name="repairDuration"
                                            type="time"
                                            label="Czas naprawy"
                                            input={<OutlinedInput label="Czas naprawy"/>}
                                            value={ticket?.repair_duration}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                    <Box p={1}>
                                        <TextField
                                            disabled
                                            key="diagnosis"
                                            fullWidth
                                            multiline
                                            name="diagnosis"
                                            label="Diagnoza"
                                            input={<OutlinedInput label="Diagnoza"/>}
                                            value={ticket?.diagnosis}
                                        />
                                    </Box>
                                    <Box p={1}>
                                        <TextField
                                            disabled
                                            key="report"
                                            fullWidth
                                            multiline
                                            name="report"
                                            label="Raport z wykonanych czynności, zalecenia"
                                            input={<OutlinedInput
                                                label="Raport z wykonanych czynności, zalecenia"/>}
                                            value={ticket?.report}
                                        />
                                    </Box>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Box>
                {!isLoadingTicket && ticket?.service_type === "paid" &&
                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h3" component="div">
                                    Płatność i faktura
                                </Typography>

                                {isLoadingApplicant && <Loader/>}
                                {!isLoadingApplicant &&
                                    <>
                                        <Box p={1}>
                                            <TextField
                                                disabled={disabledForm}
                                                key="paymentMethod"
                                                fullWidth
                                                select
                                                name="paymentMethod"
                                                label="Metoda płatności"
                                                input={<OutlinedInput label="Metoda płatności"/>}
                                                value={paymentMethod}
                                                onChange={(e) => setPaymentMethod(e.target.value)}
                                            >
                                                <MenuItem value={"cash"}>{"Za pobraniem"}</MenuItem>
                                                <MenuItem value={"online"}>{"Przelewy24"}</MenuItem>
                                            </TextField>
                                        </Box>
                                        <Box p={1}>
                                            <FormControlLabel
                                                disabled={disabledForm}
                                                key="wantInvoice"
                                                name="wantInvoice"
                                                label="Chcę fakturę"
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => setWantInvoice(e.target.checked)}
                                                        checked={wantInvoice}
                                                    />
                                                }
                                            />
                                        </Box>
                                        {wantInvoice && <>
                                            <Box p={1}>
                                                <TextField
                                                    disabled={disabledForm}
                                                    key="invoiceFullname"
                                                    name="invoiceFullname"
                                                    fullWidth
                                                    label="Imię i nazwisko na fakturze"
                                                    input={<OutlinedInput label="Imię i nazwisko na fakturze"/>}
                                                    value={invoiceFullname}
                                                    onChange={(e) => setInvoiceFullname(e.target.value)}
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    disabled={disabledForm}
                                                    key="invoiceCompany"
                                                    name="invoiceCompany"
                                                    fullWidth
                                                    label="Nazwa firmy"
                                                    input={<OutlinedInput label="Nazwa firmy"/>}
                                                    value={invoiceCompany}
                                                    onChange={(e) => setInvoiceCompany(e.target.value)}
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    disabled={disabledForm}
                                                    key="invoiceNip"
                                                    name="invoiceNip"
                                                    fullWidth
                                                    label="NIP"
                                                    input={<OutlinedInput label="NIP"/>}
                                                    value={invoiceNip}
                                                    onChange={(e) => setInvoiceNip(e.target.value)}
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    disabled={disabledForm}
                                                    key="invoiceAddress"
                                                    name="invoiceAddress"
                                                    fullWidth
                                                    label="Ulica i numer"
                                                    input={<OutlinedInput label="Ulica i numer"/>}
                                                    value={invoiceAddress}
                                                    onChange={(e) => setInvoiceAddress(e.target.value)}
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    disabled={disabledForm}
                                                    key="invoicePostcode"
                                                    name="invoicePostcode"
                                                    fullWidth
                                                    label="Kod pocztowy"
                                                    input={<OutlinedInput label="Kod pocztowy"/>}
                                                    value={invoicePostcode}
                                                    onChange={(e) => setInvoicePostcode(e.target.value)}
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    disabled={disabledForm}
                                                    key="invoiceCity"
                                                    name="invoiceCity"
                                                    fullWidth
                                                    label="Miasto"
                                                    input={<OutlinedInput label="Miasto"/>}
                                                    value={invoiceCity}
                                                    onChange={(e) => setInvoiceCity(e.target.value)}
                                                />
                                            </Box>
                                        </>
                                        }
                                    </>
                                }
                            </CardContent>
                        </Card>
                    </Box>
                }
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Części
                            </Typography>
                            {isLoadingParts && <Loader/>}
                            {!isLoadingParts &&
                                <>
                                    <UsedPartsTable usedParts={usedParts} fetchUsedParts={fetchUsedParts} noAction/>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Box>
                {
                    ticket?.is_quotation_accepted === null &&
                    ticket?.service_type === "paid" &&
                    <Box display="flex" mt={3}>
                        <span className={classes.spacer}/>
                        <Button
                            color="primary"
                            startIcon={<Check/>}
                            variant="contained"
                            disabled={paymentMethod === null}
                            onClick={() => {
                                updateQuotation(true, paymentMethod);
                            }}
                        >
                            Akceptuj wycenę
                        </Button>
                        <Box ml={1}>
                            <Button
                                color="primary"
                                startIcon={<X/>}
                                variant="contained"
                                onClick={() => {
                                    updateQuotation(false, null);
                                }}
                            >
                                Odrzuć wycenę
                            </Button>
                        </Box>
                    </Box>
                }
            </Container>
        </Page>
    );
};

export default TicketDetailsView;
