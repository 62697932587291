import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, Button, Container, makeStyles, TextField, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {useAuth} from 'src/context/auth';
import Page from 'src/components/Page';
import {api} from '../../api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setIsAuthenticated, redirectPath, setUser } = useAuth();
  const [showError, setShowError] = useState(false);

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={(values, { setSubmitting }) => {
              setShowError(false);
              api.post('users/token/obtain/', values).then(
                result => {
                  api.defaults.headers['Authorization'] = 'JWT ' + result.data.access;
                  localStorage.setItem('access_token', result.data.access);
                  localStorage.setItem('refresh_token', result.data.refresh);
                  setIsAuthenticated(true);
                  setUser({});
                  if (redirectPath) {
                    navigate(redirectPath, { replace: true });
                  } else {
                    navigate('/', { replace: true });
                  }
                }
              ).catch (() => {
                setShowError(true);
              }).finally(() => {
                setSubmitting(false);
              })
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Niepoprawny email').max(255).required('Email jest wymagany'),
              password: Yup.string().max(255).required('Hasło jest wymagana')
            })}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Logowanie
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Zaloguj się do portalu serwisowego 4swiss
                  </Typography>
                </Box>
                <Box
                  mb={1}
                  mt={3}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Zaloguj się za pomocą adresu email
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Hasło"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                {showError && <Box
                  mb={1}
                  mt={1}
                >
                  <Alert severity="error">Nie znaleziono aktywnego konta dla podanych danych!</Alert>
                </Box>}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Zaloguj się
                  </Button>
                </Box>
                {/*<Typography*/}
                {/*  color="textSecondary"*/}
                {/*  variant="body1"*/}
                {/*>*/}
                {/*  Don&apos;t have an account?*/}
                {/*  {' '}*/}
                {/*  <Link*/}
                {/*    component={RouterLink}*/}
                {/*    to="/register"*/}
                {/*    variant="h6"*/}
                {/*  >*/}
                {/*    Sign up*/}
                {/*  </Link>*/}
                {/*</Typography>*/}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
