import {Button, FormControl, Grid, makeStyles, MenuItem, OutlinedInput, TextField,} from "@material-ui/core";
import ClickableRichTooltip from "./ClickableRichTooltip";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {api} from "../api";
import {Person} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));


const TicketOwnerTooltip = ({ticket, owners}) => {
  const classes = useStyles();
  const [owner, setOwner] = useState(ticket?.owner || '');

  return (
    <ClickableRichTooltip
      content={
        <Grid
          container
          item
          key={`filter-${ticket?.id}`}
          spacing={2}
          xs={12}
        >
          <FormControl
            className={classes.formControl}
          >
            <TextField
              key="owner"
              fullWidth
              select
              id="owner"
              name="owner"
              label="Serwisant"
              input={<OutlinedInput label="Serwisant" />}
              onChange={(event) => setOwner(event.target.value)}
              value={owner}
            >
              <MenuItem key="emptyOwner" value="">-------</MenuItem>
              {owners.map(o => (
                <MenuItem key={`owner_${o.id}`} value={o.id}>{o.name}</MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      }
      onSubmit={() => {
        api.patch(`ticket_owner/${ticket?.id}/`, {
          owner: owner,
        });
      }}
      placement="bottom"
    >
      <Button
        color="primary"
        startIcon={<Person/>}
        variant="contained"
      >
          Serwisant
      </Button>
    </ClickableRichTooltip>
  );
}


TicketOwnerTooltip.propTypes = {
  className: PropTypes.string,
};

export default TicketOwnerTooltip;