import {Button, FormControl, Grid, makeStyles, MenuItem, OutlinedInput, TextField} from "@material-ui/core";
import React, {useState} from "react";
import {api} from "../../../api";
import {useParams} from "react-router-dom";
import {Autocomplete} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));


const UsedCheckListElementForm = ({fetchUsedCheckListElements, checkListElements}) => {
  const {ticketId} = useParams();
  const classes = useStyles();
  const [checkListElement, setCheckListElement] = useState(null);
  const [name, setName] = useState('');

  return (
    <Grid
      container
      item
      key={"used_check_list_element_form"}
      spacing={2}
      xs={12}
    >
      <Grid
        item
        xs={6}
      >
        <FormControl
          className={classes.formControl}
        >
          <Autocomplete
            id="checkListElement"
            value={checkListElement}
            options={checkListElements}
            getOptionLabel={(cle) => cle.name}
            renderInput={
              (params) => <TextField {...params} label="Element Check Listy" />
            }
            onChange={(event, value) => {
              setCheckListElement(value);
              if (value) {
                setName(value.name);
              }
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl className={classes.formControl}>
          <TextField
            id="name"
            label="Nazwa"
            type="text"
            value={name}
            onChange={event => setName(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
      >
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Button
          color="primary"
          disabled={!name}
          fullWidth
          size="small"
          variant="contained"
          onClick={() => {
            api.post(`ticket_check_list_elements/?ticket=${ticketId}`, {
              ticket: ticketId,
              name: name,
            }).then(() => {
              setCheckListElement(null);
              setName('');
              fetchUsedCheckListElements();
            });
          }}
        >
          Dodaj
        </Button>
      </Grid>
    </Grid>
  );
}


UsedCheckListElementForm.propTypes = {};

UsedCheckListElementForm.defaultProps = {};

export default UsedCheckListElementForm;
