import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {api} from 'src/api';


const Password = ({setOpen, setMsg}) => {
  const [error, setError] = useState('');

  return (
    <Formik
      initialValues={{
        current: '',
        password: '',
        confirm: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        api.post('users/account/password/change/', values).then(
          res => {
            setError(res.data.error);
            if (!res.data.error) {
              setOpen(true);
              setMsg('Hasło zmieniono pomyślnie!');
            }
          }
        ).finally(() => {
          setSubmitting(false);
        })
      }}
      validationSchema={Yup.object().shape({
        current: Yup.string().max(255).required('Proszę wpisać obecne hasło'),
        password: Yup.string().max(255)
          .required('Proszę wpisać nowe hasło')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            'Hasło musi zawierać co najmniej 8 znaków, ' +
            'dużą literę, mała literę, cyfrę i znak specjalny'
          ),
        confirm: Yup.string()
          .required('Proszę potwierdzić nowe hasło')
          .oneOf([Yup.ref('password'), null], 'Hasła nie zgadzają się')
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Zmień hasło"
              title="Hasło"
            />
            <Divider />
            <CardContent>
              <TextField
                error={Boolean(touched.current && errors.current)}
                fullWidth
                helperText={touched.current && errors.current}
                label="Obecne hasło"
                margin="normal"
                name="current"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.current}
                variant="outlined"
              />
              {error && <Alert severity="error">{error}</Alert>}
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Nowe hasło"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.confirm && errors.confirm)}
                fullWidth
                helperText={touched.confirm && errors.confirm}
                label="Powtórz nowe hasło"
                margin="normal"
                name="confirm"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirm}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Zaktualizuj
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

Password.propTypes = {
  setMsg: PropTypes.func,
  setOpen: PropTypes.func,
};

export default Password;
