import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import {exportFile} from "src/api";
import {Tooltip} from 'src/components';
import {Download} from "react-feather";


const PopularDefects = ({ className, defects, startDate, endDate, ...rest }) => {
  const exportDefects = () => {
    exportFile("tickets/statistics/export/defects", {startDate, endDate})
  }
  return (
    <Card {...rest}>
      <CardHeader
        title="Najpopularniejsze usterki"
        action={
          <IconButton aria-label="settings">
              <Tooltip title="Eksport">
                <Download onClick={exportDefects} />
              </Tooltip>
          </IconButton>
        }
      />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Usterka
            </TableCell>
            <TableCell>
              Urządzenie
            </TableCell>
            <TableCell>
              Kategoria
            </TableCell>
            <TableCell sortDirection="desc">
              <TableSortLabel
                active
                direction="desc"
              >
                Liczba zgłoszeń
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {defects.map((defect, idx) => (
            <TableRow
              hover
              key={`defect-${idx}`}
            >
              <TableCell>
                {defect.name}
              </TableCell>
              <TableCell>
                {defect.device}
              </TableCell>
              <TableCell>
                {defect.category}
              </TableCell>
              <TableCell>
                {defect.ticket_count}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

PopularDefects.propTypes = {
  className: PropTypes.string
};

export default PopularDefects;
