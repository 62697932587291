import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";


const ApplicantTable = ({ applicant }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Imię
                    </TableCell>
                    <TableCell>
                        Nazwisko
                    </TableCell>
                    <TableCell>
                        Email
                    </TableCell>
                    <TableCell>
                        Telefon
                    </TableCell>
                    <TableCell>
                        Ulica i numer
                    </TableCell>
                    <TableCell>
                        Kod pocztowy
                    </TableCell>
                    <TableCell>
                        Miejscowość
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow
                    hover
                    key={applicant.id}
                >
                    <TableCell>
                        {applicant.first_name}
                    </TableCell>
                    <TableCell>
                        {applicant.last_name}
                    </TableCell>
                    <TableCell>
                        {applicant.email}
                    </TableCell>
                    <TableCell>
                        {applicant.phone}
                    </TableCell>
                    <TableCell>
                        {applicant.street}
                    </TableCell>
                    <TableCell>
                        {applicant.postal_code}
                    </TableCell>
                    <TableCell>
                        {applicant.city_name}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default ApplicantTable;
