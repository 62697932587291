import React, {useEffect} from 'react';
import {api} from 'src/api';
import {useAuth} from 'src/context/auth';
import {Navigate, useLocation} from "react-router-dom";

const RouteWithUserContext = ({ component: RouteComponent }) => {
  const { isAuthenticated, user, setUser } = useAuth();
  const currentLocation = useLocation();
  useEffect(() => {
    if (Object.keys(user).length === 0) {
      api.get('users/account/').then(res => {
        setUser(res.data);
      });
    }
  });

  if (isAuthenticated && currentLocation.pathname.includes("details")) {
    return <Navigate to={`/app${currentLocation.pathname}`} />
  }

  return <RouteComponent />
};

RouteWithUserContext.defaultProps = {
  component: null,
};

export default RouteWithUserContext;
