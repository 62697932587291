import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Box, Button, CircularProgress, Container, makeStyles, OutlinedInput, TextField, Typography,} from '@material-ui/core';
import Page from 'src/components/Page';
import {api} from 'src/api';
import FileUploadComponent from "../../components/FileUploadComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TicketContactView = () => {
  const { ticketId } = useParams();

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page
      className={classes.root}
      title="Dane zgłoszenia"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              applicant: {
                street: '',
                postal_code: '',
                city_name: '',
              },
              serial_no: '',
              files: [],
            }}
            onSubmit={(values, { setSubmitting }) => {
              api.patch(`tickets/${ticketId}/`, {
                was_helpful: false,
                applicant: values.applicant,
                serial_no: values.serial_no,
              }).then(
                () => {
                  if (values.files) {
                    let form_data = new FormData();
                    form_data.append("ticket_id", ticketId);
                    Promise.all(values.files.map(file => {
                      form_data.append('file', file, file.name)
                      return api.post(`ticket_files/`, form_data, {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        }
                      });
                    })).then(() => {
                      navigate(`/ticket/done/`, { replace: true });
                    }).finally(() => {
                      setSubmitting(false);
                    });
                  } else {
                    navigate(`/ticket/done/`, { replace: true });
                  }
                }
              );
            }}
            validationSchema={
              Yup.object().shape({
                serial_no: Yup.string().max(100).required('Nr seryjny urządzenia jest wymagany'),
                applicant: Yup.object({
                  street: Yup.string().max(100).required('Ulica i numer domu są wymagane'),
                  postal_code: Yup.string().max(6).required('Kod pocztowy jest wymagany'),
                  city_name: Yup.string().max(100).required('Miejscowość jest wymagana'),
                }),
              })
            }
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Dane zgłoszenia
                  </Typography>
                  <Typography color="textSecondary" variant="h5">
                      W przypadku urządzenia na gwarancji, koniecznie proszę załączyć dowód zakupu w dowolnym formacie pliku.
                  </Typography>
                </Box>
                <FileUploadComponent files={values.files} setFieldValue={setFieldValue} />
                <TextField
                  key="serial_no"
                  error={Boolean(touched.serial_no && errors.serial_no)}
                  fullWidth
                  helperText={(touched.serial_no && errors.serial_no) || "Numer seryjny znajduje się z tyłu urządzenia"}
                  name="serial_no"
                  label="Numer seryjny urządzenia"
                  input={<OutlinedInput label="Numer seryjny urządzenia" />}
                  onChange={handleChange}
                  value={values.serial_no}
                />
                <Box mt={2}>
                  <Typography color="textSecondary" variant="h4">
                      Adres odbioru / wysyłki sprzętu
                  </Typography>
                </Box>
                <TextField
                  key="applicant.street"
                  error={Boolean(touched.applicant?.street && errors.applicant?.street)}
                  fullWidth
                  helperText={touched.applicant?.street && errors.applicant?.street}
                  name="applicant.street"
                  label="Ulica i numer"
                  input={<OutlinedInput label="Ulica i numer" />}
                  onChange={handleChange}
                  value={values.applicant.street}
                />
                <TextField
                  key="applicant.postal_code"
                  error={Boolean(touched.applicant?.postal_code && errors.applicant?.postal_code)}
                  fullWidth
                  helperText={touched.applicant?.postal_code && errors.applicant?.postal_code}
                  name="applicant.postal_code"
                  label="Kod pocztowy"
                  input={<OutlinedInput label="Kod pocztowy" />}
                  onChange={handleChange}
                  value={values.applicant.postal_code}
                />
                <TextField
                  key="applicant.city_name"
                  error={Boolean(touched.applicant?.city_name && errors.applicant?.city_name)}
                  fullWidth
                  helperText={touched.applicant?.city_name && errors.applicant?.city_name}
                  name="applicant.city_name"
                  label="Miejscowość"
                  input={<OutlinedInput label="Miejscowość" />}
                  onChange={handleChange}
                  value={values.applicant.city_name}
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Utwórz zgłoszenie&nbsp;
                    {isSubmitting && <CircularProgress />}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default TicketContactView;
