import React, {useEffect, useState} from 'react';
import {
    Box,
    Button, ButtonGroup,
    Card,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText,
    makeStyles,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import CollectionsIcon from '@mui/icons-material/Collections';
import Carousel from 'react-material-ui-carousel'
import Comments from 'src/components/Comments';
import Page from 'src/components/Page';
import {api} from 'src/api';
import {Loader, Tooltip} from 'src/components';
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import {Link, useNavigate, useParams} from "react-router-dom";
import TicketOwnerTooltip from "src/components/TicketOwnerTooltip";
import StatusChangeTooltip from "../ProjectListView/StatusChangeTooltip";
import {useAuth} from "../../../context/auth";
import * as Yup from "yup";
import {Clipboard, Edit, HelpCircle, Mail, Save, Send, Trash2} from "react-feather";
import {Formik} from "formik";
import ApplicantTable from "../ApplicantTable";
import {ArrowBack, Comment, Warning} from "@material-ui/icons";
import AddFilesTooltip from "../ProjectListView/AddFilesTooltip";
import ApplicantInvoiceTable from "../ApplicantInvoiceTable";
import TicketTable from "../TicketTable";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    spacer: {
        flexGrow: 1,
    },
    whiteButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#FFFFFF',
    },
    img: {
        textAlign: 'center',
    },
    alignTop: {
        verticalAlign: "top"
    },
    noPadding: {
        padding: 0,
    }
}));

const TicketDetailsView = () => {
    const {ticketId} = useParams();
    const classes = useStyles();
    const navigate = useNavigate();
    const {user} = useAuth();
    const [ticket, setTicket] = useState(null);
    const [ticketStatuses, setTicketStatuses] = useState([]);
    const [ticketStatusEvents, setTicketStatusEvents] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [isLoadingTicket, setIsLoadingTicket] = useState(true);
    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [isLoadingEvents, setIsLoadingEvents] = useState(true);
    const [owners, setOwners] = useState([]);
    const [serialNo, setSerialNo] = useState("");
    const [editSerialNo, setEditSerialNo] = useState(false);
    const [openModal, setOpenModal] = useState(null);
    const [openResponseModal, setOpenResponseModal] = useState(false);
    const [comments, setComments] = useState([]);
    const [editComment, setEditComment] = useState(null);
    const [contentTemplates, setContentTemplates] = useState([])
    const [openHelperModal, setOpenHelperModal] = useState(null);

    const fetchTicketMessages = () => {
        api.get(`ticket_messages/?ticket=${ticketId}`).then(res => {
            setComments(res.data);
            setIsLoadingMessages(false);
        });
    }
    useEffect(fetchTicketMessages, [ticketId]);

    const fetchContentTemplates = () => {
        api.get(`content_templates/?category_or_none=${ticket?.category?.id || ''}`).then(res => {
            setContentTemplates(res.data);
        });
    }

    useEffect(fetchContentTemplates, [ticket]);

    const manager_user = user?.is_staff || ["Admin", "Manager"].indexOf(user?.role) !== -1;
    const service_user = manager_user || user?.role === "Serwisant";

    const fetchOwners = () => {
        if (manager_user) {
            api.get('users/').then(res => {
                setOwners(res.data);
            }).catch(() => {
                // permissions error
            });
        }
    }
    useEffect(fetchOwners, []);

    const fetchTicketStatuses = () => {
        api.get('ticketstatuses/').then(res => {
            setTicketStatuses(res.data);
        });
    }
    useEffect(fetchTicketStatuses, []);

    const fetchTicket = () => {
        api.get(`tickets/${ticketId}/`).then(res => {
            setTicket(res.data);
            setSerialNo(res.data?.serial_no);
            setIsLoadingTicket(false);
            if (service_user && res.data?.is_unread) {
                api.patch(`tickets/${ticketId}/`, {
                    is_unread: false
                }).then(() => {
                    setTicket({
                        ...res.data,
                        is_unread: false
                    });
                });
            }
        });
    }

    useEffect(fetchTicket, [ticketId, service_user]);

    const fetchTicketStatusEvents = () => {
        api.get(`ticketstatusevents/?ticket=${ticketId}`).then(res => {
            setTicketStatusEvents(res.data);
            setIsLoadingEvents(false);
        });
    }

    useEffect(fetchTicketStatusEvents, [ticketId]);

    const setUnread = () => {
        api.patch(`tickets/${ticketId}/`, {
            is_unread: true
        }).then(() => {
            navigate("/app/tickets", {replace: true});
        });
    }

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const isFileImage = (file) => {
        const acceptedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        return file && acceptedExtensions.some(ext => file?.file.toLowerCase().endsWith(ext));
    }

    const serialNoCell =
        <TableCell>
            {
                !editSerialNo &&
                <Grid
                    container
                    key={`serial-no-cell-${ticket?.id}`}
                    spacing={0}
                >
                    <Grid item xs={6}>
                        {serialNo}
                    </Grid>
                    <Grid item xs={2}>
                        {
                            ticket?.has_serial_no_duplicates &&
                            <Tooltip
                                title={"Dla tego numeru seryjnego istnieje już inne zgłoszenie"}
                            >
                                <Button
                                    className={classes.noPadding}
                                    variant="text"
                                    component={Link}
                                    to={`/app/tickets?q=${serialNo}`}
                                >
                                    <Warning/>
                                </Button>
                            </Tooltip>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        {service_user &&
                            <Tooltip title="Edytuj">
                                <IconButton className={classes.noPadding}>
                                    <Edit onClick={() => setEditSerialNo(true)}/>
                                </IconButton>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
            }
            {
                service_user && editSerialNo &&
                <Formik
                    initialValues={{
                        serial_no: serialNo,
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        api.patch(`tickets/${ticketId}/`, {
                            serial_no: values.serial_no,
                        }).then((res) => {
                            setTicket(res.data)
                            setSerialNo(values.serial_no);
                            setSubmitting(false);
                            setEditSerialNo(false);
                        });
                    }}
                    validationSchema={
                        Yup.object().shape({
                            serial_no: Yup.string().max(100).required('Nr seryjny urządzenia jest wymagany'),
                        })
                    }
                >
                    {({
                          errors,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <TextField
                                key="serial_no"
                                error={Boolean(touched.serial_no && errors.serial_no)}
                                fullWidth
                                helperText={touched.serial_no && errors.serial_no}
                                name="serial_no"
                                label="Nr seryjny urządzenia"
                                input={<OutlinedInput
                                    label="Nr seryjny urządzenia"/>}
                                onChange={handleChange}
                                value={values.serial_no}
                            />
                            <Box my={2}>
                                <ButtonGroup fullWidth>
                                    <Button
                                        my={2}
                                        variant="contained"
                                        onClick={() => setEditSerialNo(false)}
                                    >
                                        Anuluj
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Zapisz
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </form>
                    )}
                </Formik>
            }
        </TableCell>;

    return (
        <Page
            className={classes.root}
            title="Szczegóły zgłoszenia"
        >
            <Container maxWidth={false}>
                {service_user && ticket &&
                    <Box display="flex">
                        <Button
                            className={classes.whiteButton}
                            startIcon={<ArrowBack/>}
                            variant="contained"
                            component={Link} to="/app/tickets"
                        >
                            Wstecz
                        </Button>
                        <span className={classes.spacer}/>
                        <Button
                            color="primary"
                            startIcon={<Mail/>}
                            variant="contained"
                            onClick={setUnread}
                        >
                            Nieprzeczytane
                        </Button>
                        <Box ml={1}>
                            <Button
                                color="primary"
                                startIcon={<Clipboard/>}
                                variant="contained"
                                component={Link} to={`/app/ticket/report/${ticketId}`}
                            >
                                Raport
                            </Button>
                        </Box>
                        <Box ml={1}>
                            <StatusChangeTooltip
                                ticket={ticket}
                                ticketStatuses={ticketStatuses}
                                fetchTickets={fetchTicketStatusEvents}
                                useButton={true}
                            />
                        </Box>
                        {manager_user && <Box ml={1}>
                            <TicketOwnerTooltip ticket={ticket} owners={owners}/>
                        </Box>}
                    </Box>
                }
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Szczegóły
                            </Typography>
                            {isLoadingTicket && <Loader/>}
                            {!isLoadingTicket &&
                                <TicketTable ticket={ticket} serialNoCell={serialNoCell}/>
                            }
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Zgłaszający
                            </Typography>
                            {isLoadingTicket && <Loader/>}
                            {!isLoadingTicket &&
                                <ApplicantTable applicant={ticket?.applicant}/>
                            }
                        </CardContent>
                    </Card>
                </Box>
                {!isLoadingTicket && ticket?.applicant.want_invoice &&
                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h3" component="div">
                                    Dane do faktury
                                </Typography>
                                <ApplicantInvoiceTable applicant={ticket?.applicant}/>
                            </CardContent>
                        </Card>
                    </Box>
                }
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Historia
                            </Typography>
                            {isLoadingEvents && <Loader/>}
                            {!isLoadingEvents &&
                                <>
                                    <PerfectScrollbar>
                                        <Box minWidth={1050}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{width: "10%"}}>
                                                            Status
                                                        </TableCell>
                                                        {service_user &&
                                                            <TableCell style={{width: "10%"}}>
                                                                Autor
                                                            </TableCell>
                                                        }
                                                        <TableCell style={{width: "10%"}}>
                                                            Data zmiany statusu
                                                        </TableCell>
                                                        <TableCell style={{width: "35%"}}>
                                                            Uwagi
                                                        </TableCell>
                                                        <TableCell style={{width: "30%"}}>
                                                            Plik
                                                        </TableCell>
                                                        {service_user &&
                                                            <TableCell style={{width: "5%"}}>
                                                                Akcje
                                                            </TableCell>
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {ticketStatusEvents.slice(page * limit, (page + 1) * limit).map((tse, index) => (
                                                        <TableRow
                                                            hover
                                                            key={"ts" + index}
                                                            className={classes.alignTop}
                                                            style={tse?.status?.color ? {backgroundColor: "#" + tse?.status?.color} : {}}
                                                        >
                                                            <TableCell>
                                                                {tse?.status?.status}
                                                            </TableCell>
                                                            {service_user &&
                                                                <TableCell>
                                                                    {tse?.created_by?.name}
                                                                </TableCell>
                                                            }
                                                            <TableCell>
                                                                {moment(tse?.timestamp).format('DD-MM-YYYY HH:mm:ss')}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    editComment === null && <>
                                                                        {tse?.comment}
                                                                        {service_user && <>
                                                                            &nbsp;
                                                                            <Tooltip title="Edytuj">
                                                                                <IconButton className={classes.noPadding}>
                                                                                    <Edit
                                                                                        onClick={() => setEditComment(tse?.id)}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>}
                                                                    </>
                                                                }
                                                                {
                                                                    service_user && editComment === tse?.id &&
                                                                    <Formik
                                                                        initialValues={{
                                                                            comment: tse?.comment
                                                                        }}
                                                                        onSubmit={(values, {setSubmitting}) => {
                                                                            api.patch(`ticketstatusevents/${tse?.id}/?ticket=${ticketId}`, {
                                                                                comment: values.comment,
                                                                            }).then(() => {
                                                                                fetchTicketStatusEvents();
                                                                                setSubmitting(false);
                                                                                setEditComment(null);
                                                                            });
                                                                        }}
                                                                    >
                                                                        {({
                                                                              errors,
                                                                              handleChange,
                                                                              handleSubmit,
                                                                              isSubmitting,
                                                                              touched,
                                                                              values
                                                                          }) => (
                                                                            <form onSubmit={handleSubmit}>
                                                                                <TextField
                                                                                    key={`comment_${tse?.id}`}
                                                                                    error={Boolean(touched.comment && errors.comment)}
                                                                                    fullWidth
                                                                                    helperText={touched.comment && errors.comment}
                                                                                    name="comment"
                                                                                    label="Nowe uwagi"
                                                                                    input={<OutlinedInput
                                                                                        label="Nowe uwagi"/>}
                                                                                    onChange={handleChange}
                                                                                    value={values.comment}
                                                                                />
                                                                                <Box my={2}>
                                                                                    <ButtonGroup fullWidth>
                                                                                        <Button
                                                                                            my={2}
                                                                                            variant="contained"
                                                                                            onClick={() => setEditComment(null)}
                                                                                        >
                                                                                            Anuluj
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="primary"
                                                                                            disabled={isSubmitting}
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                        >
                                                                                            Zapisz
                                                                                        </Button>
                                                                                    </ButtonGroup>
                                                                                </Box>
                                                                            </form>
                                                                        )}
                                                                    </Formik>
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    tse?.files.filter(isFileImage).length > 0 &&
                                                                    <Tooltip title="Podgląd">
                                                                        <IconButton
                                                                            color="primary"
                                                                            edge="end"
                                                                            onClick={() => setOpenModal(tse?.files)}
                                                                        >
                                                                            <CollectionsIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                <List>
                                                                    {tse?.files.map(file => (
                                                                        <ListItem key={file.id}>
                                                                            <ListItemText>
                                                                                <a href={file?.file}
                                                                                   target="_blank">{file?.filename}</a>
                                                                            </ListItemText>
                                                                            {service_user &&
                                                                                <ListItemSecondaryAction>
                                                                                    <Tooltip title="Usuń załącznik">
                                                                                        <IconButton
                                                                                            color="primary"
                                                                                            edge="end"
                                                                                            aria-label="delete"
                                                                                            onClick={() => {
                                                                                                api.delete(`ticketstatuseventfiles/${file.id}`).then(fetchTicketStatusEvents);
                                                                                            }}
                                                                                        >
                                                                                            <Trash2/>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </ListItemSecondaryAction>
                                                                            }
                                                                        </ListItem>
                                                                    ))}
                                                                </List>
                                                            </TableCell>

                                                            {service_user &&
                                                                <TableCell>
                                                                    <Tooltip title="Usuń status">
                                                                        <IconButton
                                                                            color="primary"
                                                                            edge="end"
                                                                            aria-label="delete"
                                                                            onClick={() => {
                                                                                api.delete(`ticketstatusevents/${tse?.id}/?ticket=${ticketId}`).then(fetchTicketStatusEvents);
                                                                            }}
                                                                        >
                                                                            <Trash2/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </PerfectScrollbar>
                                    <TablePagination
                                        component="div"
                                        count={ticketStatusEvents.length}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleLimitChange}
                                        page={page}
                                        rowsPerPage={limit}
                                        rowsPerPageOptions={[5, 10, 25]}
                                    />

                                    {
                                        openModal !== null &&
                                        <Dialog onClose={() => setOpenModal(null)} open={openModal !== null}>
                                            <DialogTitle>
                                                Podgląd
                                            </DialogTitle>
                                            <DialogContent>
                                                <Carousel height={640} sx={{width: 480}}>
                                                    {openModal.filter(isFileImage).map((file, i) => (
                                                        <Card key={`tse_file_${i}`}>
                                                            <CardMedia
                                                                key={`tse_file_img_${i}`}
                                                                component="img"
                                                                srcSet={file?.thumbnail}
                                                                alt={file?.thumbnail}
                                                            />
                                                        </Card>
                                                    ))}
                                                </Carousel>
                                            </DialogContent>
                                        </Dialog>
                                    }
                                    {
                                        openResponseModal &&
                                        <Dialog
                                            open={openResponseModal}
                                            onClose={() => setOpenResponseModal(false)}
                                            fullWidth
                                            maxWidth="lg"
                                        >
                                            <DialogTitle>Odpowiedz</DialogTitle>
                                            <DialogContent>
                                                <Formik
                                                    initialValues={{
                                                        content: "",
                                                        created_by: user?.id || null,
                                                        ticket: ticketId,
                                                    }}
                                                    onSubmit={(values, {setSubmitting}) => {
                                                        if (openHelperModal) {
                                                            return;
                                                        }
                                                        api.post(`ticket_messages/?ticket=${ticketId}`, {
                                                            content: values.content,
                                                            created_by: values.created_by,
                                                            ticket: values.ticket,
                                                        }).then(() => {
                                                            fetchTicketMessages();
                                                            setSubmitting(false);
                                                            setOpenResponseModal(false);
                                                        });
                                                    }}
                                                    validationSchema={
                                                        Yup.object().shape({
                                                            content: Yup.string().required('Wiadomość jest wymagana'),
                                                        })
                                                    }
                                                >
                                                    {({
                                                          errors,
                                                          handleChange,
                                                          handleSubmit,
                                                          isSubmitting,
                                                          touched,
                                                          values
                                                      }) => (
                                                        <form onSubmit={handleSubmit}>
                                                            <TextField
                                                                key="content"
                                                                error={Boolean(touched.content && errors.content)}
                                                                fullWidth
                                                                multiline
                                                                helperText={touched.content && errors.content}
                                                                name="content"
                                                                label="Odpowiedź"
                                                                input={<OutlinedInput label="Odpowiedź"/>}
                                                                onChange={handleChange}
                                                                value={values.content}
                                                            />
                                                            <Box display="flex" mt={2}>
                                                                {
                                                                    service_user &&
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => setOpenHelperModal({
                                                                            values: values,
                                                                            field: "content",
                                                                            fieldName: "Odpowiedź"
                                                                        })}
                                                                        variant="contained"
                                                                        startIcon={<HelpCircle/>}
                                                                    >
                                                                        Szablon treści
                                                                    </Button>
                                                                }
                                                                <span className={classes.spacer}/>
                                                                <Button
                                                                    color="primary"
                                                                    disabled={isSubmitting}
                                                                    type="submit"
                                                                    variant="contained"
                                                                    startIcon={<Send/>}
                                                                >
                                                                    Odpowiedz {service_user && ticket.applicant?.full_name}
                                                                </Button>
                                                            </Box>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </DialogContent>
                                        </Dialog>
                                    }
                                </>
                            }
                            {ticket && ticket.is_active &&
                                <Box>
                                    <AddFilesTooltip ticket={ticket} fetchTicketStatusEvents={fetchTicketStatusEvents}/>
                                </Box>
                            }
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Konwersacja
                            </Typography>
                            {isLoadingMessages && <Loader/>}
                            {!isLoadingMessages &&
                                <Box style={{width: "100%"}}>
                                    {(ticket?.is_active || service_user) &&
                                        <Button
                                            color="primary"
                                            startIcon={<Comment/>}
                                            variant="contained"
                                            onClick={() => {
                                                setOpenResponseModal(true);
                                            }}
                                        >
                                            Odpowiedz
                                        </Button>
                                    }
                                    <Comments comments={comments} ticket={ticket} fetchTicketMessages={fetchTicketMessages}/>
                                </Box>
                            }
                        </CardContent>
                    </Card>
                </Box>
                {
                    openHelperModal !== null &&
                    service_user &&
                    <Dialog onClose={() => setOpenHelperModal(null)} open={openHelperModal !== null} fullWidth
                            maxWidth="xl">
                        <DialogTitle>
                            Szablon treści dla pola: {openHelperModal?.fieldName}
                        </DialogTitle>
                        <DialogContent>
                            <Box minWidth={1050}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: "10%"}}>
                                                Tytuł
                                            </TableCell>
                                            <TableCell style={{width: "70%"}}>
                                                Opis
                                            </TableCell>
                                            <TableCell style={{width: "20%"}}>
                                                Akcje
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {contentTemplates.map((contentTemplate, index) => (
                                            <TableRow
                                                hover
                                                key={"ct" + index}
                                                className={classes.alignTop}
                                            >
                                                <TableCell>
                                                    {contentTemplate?.title}
                                                </TableCell>
                                                <TableCell>
                                                    {contentTemplate?.description}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            openHelperModal.values[openHelperModal.field] = openHelperModal.values[openHelperModal.field]+ contentTemplate?.description;
                                                            setOpenHelperModal(null);
                                                        }}
                                                        variant="contained"
                                                        startIcon={<Save/>}
                                                    >
                                                        Wybierz szablon
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </DialogContent>
                    </Dialog>
                }
            </Container>
        </Page>
    );
};

export default TicketDetailsView;
