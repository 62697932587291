import React, {useState} from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles, Snackbar
} from '@material-ui/core';
import Page from 'src/components/Page';
import Password from './Password';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  return (
    <Page
      className={classes.root}
      title="Moje konto"
    >
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        autoHideDuration={6000}
        onClose={handleClose}
        open={open}
      >
        <Alert
          onClose={handleClose}
          severity="success"
        >
          {msg}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <ProfileDetails setOpen={setOpen} setMsg={setMsg} />
          </Grid>
        </Grid>
        <Box mt={3}>
          <Password setOpen={setOpen} setMsg={setMsg} />
        </Box>
      </Container>
    </Page>
  );
};

export default Account;
