import React, {useEffect, useState} from "react";
import RichTooltip from "./RichTooltip";

import {X} from "react-feather";
import {Button, CircularProgress, Grid, IconButton, makeStyles} from "@material-ui/core";
import {Check} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  right: {
    float: 'right',
    marginLeft: theme.spacing(1),
  },
}));

const ClickableRichTooltip = ({
  placement,
  arrow,
  content,
  onShow = () => {},
  onSubmit = () => {},
  children,
  disabled = false,
  isSubmitting = null,
  noSaveButton = false,
  closeButton = false,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const existingOnClick = children.props.onClick;
  const isCustomSubmit = isSubmitting !== null;
  const newOnClick = (e) => {
    onShow();
    setOpen(!open);
    existingOnClick && existingOnClick(e);
  };

  const handleCloseAfterSubmit = () => {
    if (isSubmitting === false) {
      handleClose();
    }
  }

  useEffect(handleCloseAfterSubmit, [isSubmitting]);

  const handleSubmit = () => {
    onSubmit();
    if (isCustomSubmit === false) {
      handleClose();
    }
  }

  const handleClose = () => {
    setOpen(false);
  }

  const contentNode = <Grid container spacing={1}>
    <Grid item xs={12}>
      <IconButton className={classes.right} onClick={handleClose}>
        <X/>
      </IconButton>
    </Grid>
    {content}
    <Grid item xs={12}>
      {
        !noSaveButton &&
        <Button
          variant="contained"
          color="primary"
          className={classes.right}
          onClick={handleSubmit}
          startIcon={isSubmitting ? <CircularProgress /> : <Check/>}
          disabled={disabled || isSubmitting}
        >
          Zapisz
        </Button>
      }
      {
        closeButton &&
        <Button
          variant="contained"
          onClick={handleClose}
          startIcon={<X/>}
        >
          Zamknij
        </Button>
      }
    </Grid>
  </Grid>

  return (
    <RichTooltip
      open={open}
      // onClose={() => setOpen(false)}
      arrow={arrow}
      placement={placement}
      content={contentNode}
    >
      {React.cloneElement(children, { ...children.props, onClick: newOnClick })}
    </RichTooltip>
  );
};

export default ClickableRichTooltip;
