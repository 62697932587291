import React  from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  colors,
  useTheme
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { useAuth } from 'src/context/auth';
import { api } from 'src/api';


const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    root: {
      backgroundColor: colors.common.white,
      color: theme.palette.primary.main
    },
    avatar: {
      width: 60,
      height: 60
    },
    image: {
      marginTop: -4,
      marginBottom: -8,
      height: 72,
    }
  };
});

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { setIsAuthenticated, setUser } = useAuth();

  const logOut = () => {
    setIsAuthenticated(false);
    setUser({});
    api.post('users/token/blacklist/', {
      'refresh_token': localStorage.getItem('refresh_token')
    }).then(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      api.defaults.headers['Authorization'] = null;
    });
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="4Swiss"
            className={classes.image}
            src="/static/images/4swiss_logo.svg"
          />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit" onClick={logOut}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
