import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import {useAuth} from 'src/context/auth';
import getInitials from 'src/utils/getInitials';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 95,
    width: 95
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Box pb={2}>
            <Avatar
              className={classes.avatar}
              src={user.avatar}
            >
              {getInitials(user.name)}
            </Avatar>
          </Box>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {user.email}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            Data rejestracji: {moment(user.date_joined).format('DD-MM-YYYY')}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            Ostatnia modyfikacja: {moment(user.updated_on).format('DD-MM-YYYY HH:mm:ss')}
          </Typography>
        </Box>
      </CardContent>
      {/*<Divider />*/}
      {/*<CardActions>*/}
      {/*  <Button*/}
      {/*    color="primary"*/}
      {/*    fullWidth*/}
      {/*    variant="text"*/}
      {/*  >*/}
      {/*    Upload picture*/}
      {/*  </Button>*/}
      {/*</CardActions>*/}
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
