import {Button, FormControl, Grid, makeStyles, MenuItem, OutlinedInput, TextField} from "@material-ui/core";
import React, {useState} from "react";
import {api} from "../../../api";
import {useParams} from "react-router-dom";
import {Autocomplete} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));


const UsedPartForm = ({fetchUsedParts, parts}) => {
  const {ticketId} = useParams();
  const classes = useStyles();
  const [part, setPart] = useState(null);
  const [name, setName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState('');

  return (
    <Grid
      container
      item
      key={"used_part_form"}
      spacing={2}
      xs={12}
    >
      <Grid
        item
        xs={6}
      >
        <FormControl
          className={classes.formControl}
        >
          <Autocomplete
            id="part"
            value={part}
            options={parts}
            getOptionLabel={(p) => p.name}
            renderInput={
              (params) => <TextField {...params} label="Część" />
            }
            onChange={(event, value) => {
              setPart(value);
              if (value) {
                setName(value.name);
                setPrice(value.price);
                setDescription(value.description);
              }
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl className={classes.formControl}>
          <TextField
            id="name"
            label="Nazwa"
            type="text"
            value={name}
            onChange={event => setName(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={3}
      >
        <FormControl className={classes.formControl}>
          <TextField
            id="quantity"
            label="Liczba"
            type="number"
            value={quantity}
            onChange={event => setQuantity(event.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={3}
      >
        <FormControl className={classes.formControl}>
          <TextField
            id="price"
            label="Cena"
            type="number"
            value={price}
            onChange={event => setPrice(event.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
      >
        <FormControl className={classes.formControl}>
          <TextField
            id="description"
            label="Opis"
            type="text"
            value={description}
            onChange={event => setDescription(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={6}
      >
      </Grid>
      <Grid
        item
        xs={6}
      >
        <Button
          color="primary"
          disabled={!name || !quantity}
          fullWidth
          size="small"
          variant="contained"
          onClick={() => {
            api.post(`ticket_parts/?ticket=${ticketId}`, {
              ticket: ticketId,
              name: name,
              quantity: quantity,
              price: price,
              description: description,
            }).then(() => {
              setPart(null);
              setName('');
              setQuantity(1);
              setPrice(0);
              setDescription('');
              fetchUsedParts();
            });
          }}
        >
          Dodaj
        </Button>
      </Grid>
    </Grid>
  );
}


UsedPartForm.propTypes = {};

UsedPartForm.defaultProps = {};

export default UsedPartForm;
