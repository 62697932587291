import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Avatar, Card, CardContent, Grid, makeStyles, TextField, Typography} from '@material-ui/core';
import 'moment/locale/pl'
import {DateRange} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "#bdbdbd",
    height: 56,
    width: 56
  },
}));


const ReportDate = ({ className, date, setDate, label, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {label}
            </Typography>
            <TextField
              type="date"
              defaultValue={date}
              onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <DateRange />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ReportDate.propTypes = {
  className: PropTypes.string
};

export default ReportDate;
