import React from 'react';
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components';
import {Delete} from "@material-ui/icons";


const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
}

const DropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

const FileUploadComponent = props => {
  const { setFieldValue, maxFiles, files } = props;

  const filesNumber = maxFiles || 0;

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: filesNumber,
    onDrop: acceptedFiles => {
      let new_files = files;
      if (acceptedFiles.length > 0) {
        new_files = [...files, ...acceptedFiles];
      }
      setFieldValue("files", new_files);
    },
  });

  return (
    <DropContainer {...getRootProps({isFocused, isDragAccept, isDragReject})}>
      <input {...getInputProps()} name="files" multiple={filesNumber === 0}/>
      <p>Załącz plik</p>
      <aside>
        <ul>
          {
            files.map(file => (
              <li key={file.path}>
                {file.path}&nbsp;
                <Delete onClick={(e) => {
                  let new_files = files.filter(f => f.path !== file.path);
                  setFieldValue("files", new_files);
                  e.stopPropagation();
                }}/>
              </li>
            ))
          }
        </ul>
      </aside>
    </DropContainer>
  )
}

export default FileUploadComponent;
