import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import {Tooltip} from 'src/components';
import {InfoOutlined} from "@material-ui/icons";
import {Link} from "react-router-dom";
import SortableTableHead from '../../../components/SortableTableHead';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  alignTop: {
    verticalAlign: "top"
  },
  noPadding: {
    padding: 0
  }
}));

const Results = ({ className, defects, fetchDefects, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_on');

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, _orderBy) {
    const a_value = _orderBy.split("__").map(key => {
      a = a[key]
      return a;
    }).at(-1);
    const b_value = _orderBy.split("__").map(key => {
      b = b[key]
      return b;
    }).at(-1);

    if (b_value < a_value) {
      return -1;
    }
    if (b_value > a_value) {
      return 1;
    }
    return 0;
  }

  function getComparator(_order, _orderBy) {
    return _order === 'desc'
      ? (a, b) => descendingComparator(a, b, _orderBy)
      : (a, b) => -descendingComparator(a, b, _orderBy);
  }

  const headCells = [
    {
      id: 'device__category__name',
      label: 'Kategoria',
    },
    {
      id: 'device__name',
      label: 'Model',
    },
    {
      id: 'name',
      label: 'Usterka',
    },
    {
      id: 'created_on',
      label: 'Data utworzenia',
    },
    {
      id: 'actions',
      label: 'Akcje',
    },
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {defects.sort(getComparator(order, orderBy))
                .slice(page * limit, (page + 1) * limit).map((defect) => (
                  <TableRow
                      hover
                      key={defect.id}
                      className={classes.alignTop}
                  >
                    <TableCell>
                      {defect.device.category.name}
                    </TableCell>
                    <TableCell>
                      {defect.device.name}
                    </TableCell>
                    <TableCell>
                      {defect.name}
                    </TableCell>
                    <TableCell>
                      {moment(defect.created_on).format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Szczegóły">
                        <IconButton
                            className={classes.noPadding}
                            color="primary"
                            variant="outlined"
                            component={Link}
                            to={`/app/defect/details/${defect?.id}`}
                        >
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={defects.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  defects: PropTypes.array.isRequired
};

export default Results;
