import React, {useEffect, useState} from 'react';
import {Container, Grid, makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import AverageRepairDuration from './AverageRepairDuration';
import PopularDefects from './PopularDefects';
import TicketsProgress from './TicketsProgress';
import TotalTickets from './TotalTickets';
import TotalCost from './TotalCost';
import {api} from "../../../api";
import {Loader} from "../../../components";
import PopularParts from "./PopularParts";
import ReportDate from "./ReportDate";
import DeviceSummary from "./DeviceSummary";
import ServiceTechnicianSummary from "./ServiceTechnicianSummary";
import TicketSummary from "./TicketSummary";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [statistics, setStatistics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 8) + '01');
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));

  const fetchStatistics = () => {
    api.get("tickets/statistics", {params: {startDate, endDate}}).then(res => {
        setStatistics(res.data);
        setIsLoading(false);
    });
  }
  useEffect(fetchStatistics, [startDate, endDate]);

  return (
    <Page
      className={classes.root}
      title="Statystyki"
    >
      {isLoading && <Loader/>}
      {!isLoading &&
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ReportDate date={startDate} setDate={setStartDate} label="Data początkowa"/>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ReportDate date={endDate} setDate={setEndDate} label="Data końcowa"/>
            </Grid>
            <Grid item lg={6}  sm={12} xl={6} xs={12} />

            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <AverageRepairDuration statistics={statistics}/>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalTickets statistics={statistics}/>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TicketsProgress statistics={statistics}/>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCost statistics={statistics}/>
            </Grid>

            <Grid item lg={12} md={12} xl={12} xs={12}>
              <DeviceSummary devices={statistics.devices} startDate={startDate} endDate={endDate}/>
            </Grid>

            <Grid item lg={8} md={12} xl={9} xs={12}>
              <ServiceTechnicianSummary
                serviceTechnicians={statistics.service_technicians}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <TicketSummary tickets={statistics.tickets} startDate={startDate} endDate={endDate}/>
            </Grid>

            <Grid item lg={8} md={12} xl={9} xs={12}>
              <PopularDefects defects={statistics.defects} startDate={startDate} endDate={endDate}/>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <PopularParts parts={statistics.parts} startDate={startDate} endDate={endDate}/>
            </Grid>
          </Grid>
        </Container>
      }
    </Page>
  );
};

export default Dashboard;
