import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import Page from 'src/components/Page';
import {api} from 'src/api';
import {Loader} from 'src/components';
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import {useAuth} from "../../../context/auth";
import {ArrowBack} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    spacer: {
        flexGrow: 1,
    },
    whiteButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#FFFFFF',
    },
    alignTop: {
        verticalAlign: "top"
    },
    noPadding: {
        padding: 0,
    }
}));

const TicketDetailsView = () => {
    const { defectId } = useParams();
    const classes = useStyles();
    const { user } = useAuth();
    const [defect, setDefect] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const service_user = user?.role !== undefined;

    const fetchTicket = () => {
        api.get(`defects_full/${defectId}/`).then(res => {
            setDefect(res.data);
            setIsLoading(false);
        });
    }
    useEffect(fetchTicket, []);

    return (
        <Page
            className={classes.root}
            title="Szczegóły usterki"
        >
            <Container maxWidth={false}>
                {service_user && defect &&
                    <Box display="flex">
                        <Button
                            className={classes.whiteButton}
                            startIcon={<ArrowBack />}
                            variant="contained"
                            component={Link} to="/app/defects"
                        >
                            Wstecz
                        </Button>
                        <span className={classes.spacer} />
                    </Box>
                }
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                              Szczegóły
                            </Typography>
                            {isLoading && <Loader/>}
                            {!isLoading &&
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Kategoria
                                            </TableCell>
                                            <TableCell>
                                                Model
                                            </TableCell>
                                            <TableCell>
                                                Usterka
                                            </TableCell>
                                            {service_user && <>
                                                <TableCell>
                                                    Data utworzenia
                                                </TableCell>
                                                <TableCell>
                                                    Data ostatniej modyfikacji
                                                </TableCell>
                                            </>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow className={classes.alignTop}>
                                            <TableCell>
                                                {defect.device.category.name}
                                            </TableCell>
                                            <TableCell>
                                                {defect.device.name}
                                            </TableCell>
                                            <TableCell>
                                                {defect.name}
                                            </TableCell>
                                            {service_user && <>
                                                <TableCell>
                                                    {moment(defect?.created_on).format('DD-MM-YYYY HH:mm:ss')}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(defect?.updated_on).format('DD-MM-YYYY HH:mm:ss')}
                                                </TableCell>
                                            </>}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            }
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                              Proponowane rozwiązanie
                            </Typography>
                            {isLoading && <Loader/>}
                            {!isLoading &&
                                <div dangerouslySetInnerHTML={{__html: defect.solution}} />
                            }
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
};

export default TicketDetailsView;
