import {Box, Button, Divider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {api} from "../../../api";
import {useParams} from "react-router-dom";
import {Trash2} from "react-feather";
import moment from "moment/moment";


const OrdersTable = ({orders, fetchOrders}) => {
    const {ticketId} = useParams();
    if (orders.length === 0) {
        return <></>
    }
    return (
        <Box my={2}>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Identyfikator
                        </TableCell>
                        <TableCell>
                            Data utworzenia
                        </TableCell>
                        <TableCell>
                            Typ
                        </TableCell>
                        <TableCell>
                            Link
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((order, index) =>
                        <TableRow
                            hover
                            key={`orders${index}`}
                        >
                            <TableCell>
                                {order.order_id}
                            </TableCell>
                            <TableCell>
                                {moment(order.created_on).format('DD-MM-YYYY HH:mm:ss')}
                            </TableCell>
                            <TableCell>
                                {order.direction_display}
                            </TableCell>
                            <TableCell>
                                {
                                    order.order_page.startsWith("http") &&
                                    <a href={order.order_page} target="_blank">{order.order_page}</a>
                                }
                                {
                                    !order.order_page.startsWith("http") && order.order_page
                                }
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
}

OrdersTable.propTypes = {};

OrdersTable.defaultProps = {};

export default OrdersTable;