import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import {useAuth} from 'src/context/auth';
import {api} from 'src/api';
import * as Yup from 'yup';
import {Formik} from 'formik';


const ProfileDetails = ({setOpen, setMsg}) => {
  const { user, setUser } = useAuth();

  if (Object.keys(user).length === 0) {
    return ""
  }

  return (
    <Formik
      initialValues={{
        first_name: user.first_name,
        last_name: user.last_name
      }}
      onSubmit={(values, { setSubmitting }) => {
        api.post('users/account/', values).then(
          res => {
            setUser(res.data);
            setOpen(true);
            setMsg('Szczegóły konto zostały zaktualizowane pomyślnie!');
          }
        ).finally(() => {
          setSubmitting(false);
        })
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().max(255).required('Imię jest wymagana'),
        last_name: Yup.string().max(255).required('Nazwisko jest wymagana')
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Poniższe informacje mogą być edytowane"
              title="Profil"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.first_name && errors.first_name)}
                    fullWidth
                    helperText={touched.first_name && errors.first_name}
                    label="Imię"
                    margin="normal"
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.last_name && errors.last_name)}
                    fullWidth
                    helperText={touched.last_name && errors.last_name}
                    label="Nazwisko"
                    margin="normal"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Zapisz
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  setOpen: PropTypes.func,
  setMsg: PropTypes.func,
};

export default ProfileDetails;
