import { createTheme, colors } from '@material-ui/core';
import { plPL } from '@material-ui/core/locale';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme(
  {
    palette: {
      background: {
        dark: '#F4F6F8',
        default: colors.common.white,
        paper: colors.common.white
      },
      primary: {
        light: '#757ce8',
        main: '#f23630',
        dark: '#d32e27',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows,
    typography,
    overrides: {
      MuiDialogTitle: {
        root: {
          '& h2': {
            fontSize: '1.25rem',
            letterSpacing: '0.0075em',
          }
        }
      },
    },
  },
  plPL
);

export default theme;
