import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import {Tooltip} from 'src/components';
import {InfoOutlined, Warning} from "@material-ui/icons";
import StatusChangeTooltip from "./StatusChangeTooltip";
import ApplicantTooltip from "./ApplicantTooltip";
import {Link} from "react-router-dom";
import SortableTableHead from '../../../components/SortableTableHead';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  bold: {
    fontWeight: "bold !important"
  },
  alignTop: {
    verticalAlign: "top"
  },
  noPadding: {
    padding: 0
  }
}));

const Results = ({ className, tickets, fetchTickets, ticketStatuses, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(JSON.parse(localStorage.getItem("limit")) || 25);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('status_time');

  useEffect(() => setPage(0), [tickets]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    localStorage.setItem("limit", JSON.stringify(event.target.value));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, _orderBy) {
    const a_value = _orderBy.split("__").map(key => {
      a = a[key]
      return a;
    }).at(-1);
    const b_value = _orderBy.split("__").map(key => {
      b = b[key]
      return b;
    }).at(-1);

    if (b_value < a_value) {
      return -1;
    }
    if (b_value > a_value) {
      return 1;
    }
    return 0;
  }

  function getComparator(_order, _orderBy) {
    return _order === 'desc'
      ? (a, b) => descendingComparator(a, b, _orderBy)
      : (a, b) => -descendingComparator(a, b, _orderBy);
  }

  const headCells = [
    {
      id: 'number',
      label: 'Numer',
    },
    {
      id: 'category__name',
      label: 'Kategoria',
    },
    {
      id: 'device__name',
      label: 'Model',
    },
    {
      id: 'defect__name',
      label: 'Usterka',
    },
    {
      id: 'applicant__full_name',
      label: 'Zgłaszający',
    },
    {
      id: 'status__status',
      label: 'Status',
    },
    {
      id: 'status_time',
      label: 'Data ostatniej zmiany statusu',
    },
    {
      id: 'actions',
      label: 'Akcje',
    },
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {tickets.sort(getComparator(order, orderBy))
                .slice(page * limit, (page + 1) * limit).map((ticket) => (
                  <TableRow
                      hover
                      key={ticket.id}
                      className={classes.alignTop}
                      style={ticket?.status?.color ? {backgroundColor: "#" + ticket?.status?.color} : {}}
                  >
                    <TableCell className={ticket?.is_unread ? classes.bold : ""}>
                      {ticket.number}
                    </TableCell>
                    <TableCell className={ticket?.is_unread ? classes.bold : ""}>
                      {ticket.category.name}
                    </TableCell>
                    <TableCell className={ticket?.is_unread ? classes.bold : ""}>
                      {ticket?.device?.name}
                    </TableCell>
                    <TableCell className={ticket?.is_unread ? classes.bold : ""}>
                      <Tooltip title={ticket.defect_description}>
                        <div >
                          {ticket?.defect?.name}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={ticket?.is_unread ? classes.bold : ""}>
                      {ticket.applicant?.first_name} {ticket.applicant?.last_name}
                    </TableCell>
                    <TableCell className={ticket?.is_unread ? classes.bold : ""}>
                      <Grid
                          container
                          key={`status-cell-${ticket.id}`}
                          spacing={1}
                      >
                        <Grid item xs={10}>
                          {ticket?.status?.status}
                        </Grid>
                        <Grid item xs={2}>
                          {
                            ticket?.status_valid_until && moment().diff(ticket?.status_valid_until) >= 0 &&
                            <Tooltip title={"Minęła data okresu ważności statusu (" + moment(ticket?.status_valid_until).format('DD-MM-YYYY HH:mm:ss') + ")"}>
                              <Warning/>
                            </Tooltip>
                          }
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell className={ticket?.is_unread ? classes.bold : ""}>
                      {moment(ticket.status_time).format('DD-MM-YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      <Grid
                          container
                          key={`actions-cell-${ticket.id}`}
                          spacing={2}
                      >
                        <Grid item xs={4}>
                          <StatusChangeTooltip
                              ticket={ticket}
                              ticketStatuses={ticketStatuses}
                              fetchTickets={fetchTickets}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          {ticket?.applicant?.id &&
                            <ApplicantTooltip applicant={ticket?.applicant}/>
                          }
                        </Grid>
                        <Grid item xs={4}>
                          <Tooltip title="Szczegóły">
                            <IconButton
                                className={classes.noPadding}
                                color="primary"
                                variant="outlined"
                                component={Link}
                                to={`/app/ticket/details/${ticket?.id}`}
                            >
                              <InfoOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={tickets.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  tickets: PropTypes.array.isRequired
};

export default Results;
