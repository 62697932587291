import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {AppBar, colors, makeStyles, Toolbar, useTheme} from '@material-ui/core';

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    root: {
      backgroundColor: colors.common.white,
      color: theme.palette.primary.main
    },
    image: {
      marginTop: -4,
      marginBottom: -8,
      height: 72,
    }
  };
});

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="4Swiss"
            className={classes.image}
            src="/static/images/4swiss_logo.svg"
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
