import {Button, FormControl, Grid, makeStyles,} from "@material-ui/core";
import {File} from "react-feather";
import ClickableRichTooltip from "../../../components/ClickableRichTooltip";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {api} from "../../../api";
import FileUploadComponent from "../../../components/FileUploadComponent";

const useStyles = makeStyles((theme) => ({
    root: {},
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
}));


const AddFilesTooltip = ({ className, ticket, fetchTicketStatusEvents, ...rest}) => {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onShow = () => {
        setFiles([]);
    }

    return (
        <ClickableRichTooltip
            content={
                <Grid
                    container
                    item
                    key={`add-files-${ticket.id}`}
                    spacing={2}
                    xs={12}
                >
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <FileUploadComponent files={files} setFieldValue={(_, _files) => setFiles(_files)}/>
                        </FormControl>
                    </Grid>
                </Grid>
            }
            onShow={onShow}
            disabled={files?.length === 0}
            isSubmitting={isSubmitting}
            onSubmit={() => {
                if (files?.length > 0) {
                    setIsSubmitting(true);
                    let form_data = new FormData();
                    form_data.append("ticket_id", ticket?.id);
                    form_data.append("add_files", true);
                    Promise.all(files.map(file => {
                        form_data.append('file', file, file.name)
                        return api.post(`ticket_files/`, form_data, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            }
                        });
                    })).finally(() => {
                        setIsSubmitting(false);
                        fetchTicketStatusEvents();
                    });
                } else {
                    fetchTicketStatusEvents();
                }
            }}
            placement="left"
        >
            <Button
                color="primary"
                startIcon={<File />}
                variant="contained"
            >
                Załącz pliki
            </Button>
        </ClickableRichTooltip>
    );
}


AddFilesTooltip.propTypes = {
    className: PropTypes.string,
};

AddFilesTooltip.defaultProps = {
    fetchTicketStatusEvents: () => {},
};

export default AddFilesTooltip;
