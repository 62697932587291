import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {useState} from 'react';
import { ThemeProvider } from '@material-ui/core';
import { AuthContext } from 'src/context/auth';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import AppRoutes from 'src/AppRoutes';

const App = () => {
  const getInitialIsAuthenticated = () => {
    const refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

      // exp date in token is expressed in seconds, while now() returns milliseconds:
      const now = Math.ceil(Date.now() / 1000);
      return tokenParts.exp > now;
    }
    return false;
  }
  const [isAuthenticated, setIsAuthenticated] = useState(getInitialIsAuthenticated());
  const [redirectPath, setRedirectPath] = useState();
  const [user, setUser] = useState({});

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthContext.Provider
        value={{ isAuthenticated, setIsAuthenticated, redirectPath, setRedirectPath, user, setUser }}
      >
        <AppRoutes />
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

export default App;
