import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import {api} from 'src/api';
import {useNavigate, useParams} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  solution: {
    padding: "unset",
    "& ul": {
      paddingLeft: theme.spacing(3),
    }
  }
}));


const TicketSolutionView = () => {
  const navigate = useNavigate();

  const { ticketId } = useParams();
  const classes = useStyles();
  const [ticket, setTicket] = useState(null);

  const fetchTicket = () => {
    api.get(`tickets/${ticketId}/`).then(res => {
      setTicket(res.data);
    });
  }

  useEffect(fetchTicket, [ticketId]);

  return (
    <Page
      className={classes.root}
      title="Proponowane rozwiązanie"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Szczegóły zgłoszenia:
            </Typography>
          </Box>
          <Box mb={3}>
            <TableContainer component={Card}>
              <Table>
                <TableBody>
                  <TableRow key="category_row">
                    <TableCell component="th" scope="row">
                      Kategoria
                    </TableCell>
                    <TableCell align="right">
                      {ticket?.category?.name}
                    </TableCell>
                  </TableRow>
                  <TableRow key="device_row">
                    <TableCell component="th" scope="row">
                      Model
                    </TableCell>
                    <TableCell align="right">
                      {ticket?.device?.name}
                    </TableCell>
                  </TableRow>
                  <TableRow key="defect_row">
                    <TableCell component="th" scope="row">
                      Usterka
                    </TableCell>
                    <TableCell align="right">
                      {ticket?.defect?.name}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Proponowane rozwiązanie:
            </Typography>
          </Box>
          <Card>
            <CardContent>
              <div className={classes.solution} dangerouslySetInnerHTML={{__html: ticket?.defect?.solution}} />
            </CardContent>
            <CardContent>
                <Typography color="textSecondary" variant="h5">
                    Po kliknięciu "Nie pomogło" zostanie wyświetlona dalsza część zgłoszenia reklamacji
                </Typography>
                <Typography color="textSecondary" variant="h5">
                    Po kliknięciu "Pomogło" zlecenie jest anulowane, rozwiązanie problemu zostanie wysłane droga mailową
                </Typography>
            </CardContent>
          </Card>
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => {
                    navigate(`/ticket/contact/${ticketId}`);
                  }}
                >
                  Nie pomogło
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => {
                    navigate("/ticket/done/");
                  }}
                >
                  Pomogło
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default TicketSolutionView;
