import React, {useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { api } from 'src/api';
import { useAuth } from 'src/context/auth';

const PrivateRoute = ({ component: RouteComponent }) => {
  const { isAuthenticated, setRedirectPath, user, setUser } = useAuth();
  const currentLocation = useLocation();
  useEffect(() => {
    if (!isAuthenticated) {
      setRedirectPath(currentLocation.pathname);
    } else if (Object.keys(user).length === 0) {
      api.get('users/account/').then(res => {
        setUser(res.data);
      });
    }
  });

  if (isAuthenticated) {
    return <RouteComponent />
  } else if (currentLocation.pathname.includes("details")) {
    return <Navigate to={currentLocation.pathname.replace("/app", "")} />
  }

  return <Navigate to="/login" />;
};

PrivateRoute.defaultProps = {
  component: null,
};

export default PrivateRoute;
