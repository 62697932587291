import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProjectListView from 'src/views/project/ProjectListView';
import DashboardView from 'src/views/reports/DashboardView';
import RegisterView from 'src/views/auth/RegisterView';
import UserListView from 'src/views/user/UserListView';
import AddTicketView from "./views/project/AddTicketView";
import PrivateRoute from 'src/components/PrivateRoute';
import TicketDetailsView from "./views/project/TicketDetailsView";
import TicketRepairView from "./views/project/TicketRepairView";
import TicketSolutionView from "./views/project/TicketSolutionView";
import TicketDoneView from "./views/project/TicketDone";
import TicketContactView from "./views/project/TicketContactView";
import TicketReportView from "./views/project/TicketReportView";
import RouteWithUserContext from "./components/RouteWithUserContext";
import DefectListView from "./views/defect/DefectListView";
import DefectDetailsView from "./views/defect/DefectDetailsView";


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/app" element={<PrivateRoute component={DashboardLayout} />}>
        <Route path="account" element={<AccountView />} />
        <Route exact path="tickets" element={<ProjectListView />} />
        <Route path="ticket/details/:ticketId" element={<TicketDetailsView />} />
        <Route path="ticket/report/:ticketId" element={<TicketReportView />} />
        <Route path="defects" element={<DefectListView />} />
        <Route path="defect/details/:defectId" element={<DefectDetailsView />} />
        <Route path="users" element={<UserListView />} />
        <Route path="reports" element={<DashboardView />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
      <Route path="/" element={<RouteWithUserContext component={MainLayout} />}>
        <Route path="ticket" element={<AddTicketView />} />
        <Route path="ticket/repair/:ticketId" element={<TicketRepairView />} />
        <Route path="ticket/solution/:ticketId" element={<TicketSolutionView />} />
        <Route path="ticket/contact/:ticketId" element={<TicketContactView />} />
        <Route path="ticket/done/" element={<TicketDoneView />} />
        <Route path="ticket/details/:ticketId" element={<TicketDetailsView />} />
        <Route path="defects" element={<DefectListView />} />
        <Route path="defect/details/:defectId" element={<DefectDetailsView />} />
        <Route path="login" element={<LoginView />} />
        <Route path="register" element={<RegisterView />} />
        <Route path="404" element={<NotFoundView />} />
        <Route path="/" element={<Navigate to="/app/tickets" />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
