import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    OutlinedInput,
    TextField,
} from "@material-ui/core";
import {Tooltip} from 'src/components';
import {Edit, Plus} from "react-feather";
import ClickableRichTooltip from "../../../components/ClickableRichTooltip";
import React, {useState} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {api} from "../../../api";
import FileUploadComponent from "../../../components/FileUploadComponent";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    root: {},
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
}));


const StatusChangeTooltip = ({ className, ticket, ticketStatuses, fetchTickets, useButton, ...rest}) => {
    const classes = useStyles();
    const [ticketStatus, setTicketStatus] = useState();
    const dateFormat = 'YYYY-MM-DDTHH:mm';
    const [statusValidUntil, setStatusValidUntil] = useState();
    const [comment, setComment] = useState("");
    const [files, setFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onShow = () => {
        setTicketStatus(null);
        setStatusValidUntil(null);
        setComment("");
        setFiles([]);
    }

    return (
        <ClickableRichTooltip
            content={
                <Grid
                    container
                    item
                    key={`filter-${ticket.id}`}
                    spacing={2}
                    xs={12}
                >
                    {ticket.is_completed &&
                        <Grid item xs={12}>
                            <Alert severity="warning">Zgłoszenie jest zakończone. Upewnij się, że chcesz zmienić jego status.</Alert>
                        </Grid>
                    }
                    <Grid
                        item
                        xs={6}
                    >
                        <FormControl
                            className={classes.formControl}
                        >
                            <TextField
                                fullWidth
                                select
                                id="ticketStatus"
                                value={ticketStatus}
                                label="Status"
                                input={<OutlinedInput label="Status" />}
                                onChange={event => {
                                    const tId = event.target.value;
                                    setTicketStatus(tId);
                                    if (tId !== ticket.status?.id) {
                                        const ticketStatusIndex = ticketStatuses.findIndex(tStatus => {
                                            return tStatus.id === tId;
                                        });
                                        if (ticketStatusIndex !== -1) {
                                            const addDays = ticketStatuses[ticketStatusIndex].valid_for;
                                            if (addDays && addDays > 0) {
                                                setStatusValidUntil(moment().add(addDays, 'days').format(dateFormat));
                                                return
                                            }
                                        }
                                    }
                                    setStatusValidUntil(null);
                                }}
                            >
                                <MenuItem key="emptyStatus" value="">-------</MenuItem>
                                {ticketStatuses.map(ts => (
                                    <MenuItem key={`status_${ts.id}`} value={ts.id}>{ts.status}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="date"
                                label="Data ważności statusu"
                                type="datetime-local"
                                value={statusValidUntil}
                                onChange={event => setStatusValidUntil(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <TextField
                              id="comment"
                              label="Uwagi"
                              type="text"
                              value={comment}
                              onChange={event => setComment(event.target.value)}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <FileUploadComponent files={files} setFieldValue={(_, _files) => setFiles(_files)}/>
                        </FormControl>
                    </Grid>
                </Grid>
            }
            onShow={onShow}
            disabled={!ticketStatus}
            isSubmitting={isSubmitting}
            onSubmit={() => {
                setIsSubmitting(true);
                api.patch(`tickets/${ticket.id}/`, {
                    status: ticketStatus,
                    status_valid_until: statusValidUntil,
                    status_time: moment().format(dateFormat),
                    comment: comment,
                }).then(() => {
                    if (files?.length > 0) {
                        let form_data = new FormData();
                        form_data.append("ticket_id", ticket?.id);
                        Promise.all(files.map(file => {
                            form_data.append('file', file, file.name)
                            return api.post(`ticket_files/`, form_data, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                }
                            });
                        })).finally(() => {
                            fetchTickets();
                            setIsSubmitting(false);
                        });
                    } else {
                        setIsSubmitting(false);
                        fetchTickets();
                    }
                });
            }}
            placement="bottom"
        >
            <Box>
                {useButton &&
                    <Button
                        color="primary"
                        startIcon={<Plus />}
                        variant="contained"
                    >
                        Nowy status
                    </Button>
                }
                {!useButton &&
                    <Tooltip title="Nowy status">
                        <IconButton style={{padding: 0}}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        </ClickableRichTooltip>
    );
}


StatusChangeTooltip.propTypes = {
    className: PropTypes.string,
    useButton: PropTypes.bool,
};

StatusChangeTooltip.defaultProps = {
    fetchTickets: () => {},
    useButton: false,
};

export default StatusChangeTooltip;
