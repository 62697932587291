import {Box, Button, Divider, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {api} from "../../../api";
import {useParams} from "react-router-dom";
import {Trash2} from "react-feather";


const UsedCheckListElementTable = ({fetchUsedCheckListElements, usedCheckListElements}) => {
    const {ticketId} = useParams();
    if (usedCheckListElements.length === 0) {
        return <></>
    }
    return (
        <Box my={2}>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Nazwa
                        </TableCell>
                        <TableCell>
                            Akcje
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usedCheckListElements.map((usedCheckListElement, index) =>
                        <TableRow
                            hover
                            key={`usedPart${index}`}
                        >
                            <TableCell>
                                {usedCheckListElement.name}
                            </TableCell>
                            <TableCell>
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    startIcon={<Trash2/>}
                                    onClick={() => {
                                      api.delete(`ticket_check_list_elements/${usedCheckListElement.id}/?ticket=${ticketId}`, ).then(fetchUsedCheckListElements);
                                    }}
                                >
                                    Usuń
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
}

UsedCheckListElementTable.propTypes = {};

UsedCheckListElementTable.defaultProps = {};

export default UsedCheckListElementTable;