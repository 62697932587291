import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  OutlinedInput,
  MenuItem, CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import {api} from 'src/api';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddTicketView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [defects, setDefects] = useState([]);
  const [devices, setDevices] = useState([]);

  const fetchDefects = () => {
    api.get('defects/').then(res => {
      setDefects(res.data);
    });
  }

  useEffect(fetchDefects, []);

  const fetchDevices = () => {
    api.get('devices/').then(res => {
      setDevices(res.data);
    });
  }

  useEffect(fetchDevices, []);

  const fetchCategories = () => {
    api.get('categories/').then(res => {
      setCategories(res.data);
    });
  }

  useEffect(fetchCategories, []);

  return (
    <Page
      className={classes.root}
      title="Nowe zgłoszenie"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              category: '',
              defect: '',
              defect_description: '',
              device: '',
              applicant: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
              },
            }}
            onSubmit={(values, { setSubmitting }) => {
              api.post('tickets/', {
                category: values.category,
                defect: values.defect,
                defect_description: values.defect_description,
                device: values.device,
                applicant: values.applicant,
              }).then(
                (res) => {
                  const defect = defects.find(d => d.id === values.defect);
                  const nextStep = (defect.has_solution) ? "solution" : "contact";
                  navigate(`/ticket/${nextStep}/${res.data.id}`, {replace: true});
                }
              ).finally(() => {
                setSubmitting(false);
              })
            }}
            validationSchema={
              Yup.object().shape({
                category: Yup.string().max(255).required('Kategoria jest wymagana'),
                defect: Yup.string().max(255).required('Usterka jest wymagana'),
                device: Yup.string().max(255).required('Model jest wymagany'),
                applicant: Yup.object({
                  first_name: Yup.string().max(100).required('Imię jest wymagane'),
                  last_name: Yup.string().max(100).required('Nazwisko jest wymagane'),
                  email: Yup.string().email('Niepoprawny adres email').required('Email jest wymagany'),
                }),
              })
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Nowe zgłoszenie
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Opisz szczegóły Twojego problemu
                  </Typography>
                </Box>
                <TextField
                  key="category"
                  error={Boolean(touched.category && errors.category)}
                  fullWidth
                  select
                  helperText={touched.category && errors.category}
                  id="category"
                  name="category"
                  label="Kategoria"
                  input={<OutlinedInput label="Kategoria" />}
                  onChange={(event) => {
                    const new_category = event.target.value;
                    if (devices.filter(d => d.category_id === new_category).indexOf(values.device) === -1) {
                      setFieldValue('device', '');
                      setFieldValue('defect', '');
                    }
                    setFieldValue('category', new_category);
                  }}
                  value={values.category}

                >
                  <MenuItem key="emptyStatus" value="">-------</MenuItem>
                  {categories.map(c => (
                    <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  key="device"
                  error={Boolean(touched.device && errors.device)}
                  fullWidth
                  select
                  helperText={touched.device && errors.device}
                  name="device"
                  label="Model"
                  input={<OutlinedInput label="Model" />}
                  onChange={(event) => {
                    const new_device = event.target.value;
                    if (defects.filter(d => d.device_id === new_device).indexOf(values.defect) === -1) {
                      setFieldValue('defect', '');
                    }
                    setFieldValue('device', new_device);
                  }}
                  value={values.device}
                >
                  <MenuItem key="emptyDevice" value="">-------</MenuItem>
                  {devices.filter(d => d.category_id === values.category).map(d => (
                    <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  key="defect"
                  error={Boolean(touched.defect && errors.defect)}
                  fullWidth
                  select
                  helperText={touched.defect && errors.defect}
                  name="defect"
                  label="Usterka"
                  input={<OutlinedInput label="Usterka" />}
                  onChange={handleChange}
                  value={values.defect}

                >
                  <MenuItem key="emptyDefect" value="">-------</MenuItem>
                  {defects.filter(d => d.device_id === values.device).map(d => (
                    <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  key="defect_description"
                  error={Boolean(touched.defect_description && errors.defect_description)}
                  fullWidth
                  multiline
                  helperText={touched.defect_description && errors.defect_description}
                  name="defect_description"
                  label="Opis usterki"
                  input={<OutlinedInput label="Opis usterki"/>}
                  onChange={handleChange}
                  value={values.defect_description}
                />
                <TextField
                  key="applicant.first_name"
                  error={Boolean(touched.applicant?.first_name && errors.applicant?.first_name)}
                  fullWidth
                  helperText={touched.applicant?.first_name && errors.applicant?.first_name}
                  name="applicant.first_name"
                  label="Imię"
                  input={<OutlinedInput label="Imię" />}
                  onChange={handleChange}
                  value={values.applicant.first_name}
                />
                <TextField
                  key="applicant.last_name"
                  error={Boolean(touched.applicant?.last_name && errors.applicant?.last_name)}
                  fullWidth
                  helperText={touched.applicant?.last_name && errors.applicant?.last_name}
                  name="applicant.last_name"
                  label="Nazwisko"
                  input={<OutlinedInput label="Nazwisko" />}
                  onChange={handleChange}
                  value={values.applicant.last_name}
                />
                <TextField
                  key="applicant.email"
                  error={Boolean(touched.applicant?.email && errors.applicant?.email)}
                  fullWidth
                  helperText={touched.applicant?.email && errors.applicant?.email}
                  name="applicant.email"
                  label="Email"
                  input={<OutlinedInput label="Email" />}
                  onChange={handleChange}
                  value={values.applicant.email}
                />
                <TextField
                  key="applicant.phone"
                  error={Boolean(touched.applicant?.phone && errors.applicant?.phone)}
                  fullWidth
                  helperText={touched.applicant?.phone && errors.applicant?.phone}
                  name="applicant.phone"
                  label="Telefon"
                  input={<OutlinedInput label="Telefon" />}
                  onChange={handleChange}
                  value={values.applicant.phone}
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Dalej&nbsp;
                    {isSubmitting && <CircularProgress />}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default AddTicketView;
