export const PROD_ENV = process.env.REACT_APP_NODE_ENV === 'prod';
export const DEV_ENV = process.env.REACT_APP_NODE_ENV === 'dev';
export const LOCAL_ENV = !PROD_ENV && !DEV_ENV;

let apiUrl, sentryJsDsn;
if (PROD_ENV) {
  apiUrl = 'https://serwis.4swiss.info/api';
  sentryJsDsn = 'https://869bb218db8e4ea58f57500beacfaae8@o1196652.ingest.sentry.io/4505578814504960';
} else if (DEV_ENV) {
  apiUrl = 'https://4swiss.blacktest.pl/api';
  sentryJsDsn = 'https://869bb218db8e4ea58f57500beacfaae8@o1196652.ingest.sentry.io/4505578814504960';
} else {
  apiUrl = 'http://localhost:8000/api';
  sentryJsDsn = '';
}
export const API_URL = apiUrl;
export const SENTRY_JS_DSN = sentryJsDsn;
export const SENTRY_JS_ENV = process.env.REACT_APP_NODE_ENV;
