import {Tooltip, withStyles} from '@material-ui/core';

const styles = theme => {
    return {
      tooltip: {
        fontSize: "16px"
      }
    };
};

export default withStyles(styles)(Tooltip);
