import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors, Box
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  },
  differenceIconUp: {
    color: colors.red[900],
  },
  differenceValueUp: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  differenceIconDown: {
    color: colors.green[900],
  },
  differenceValueDown: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalCost = ({ className, statistics, ...rest }) => {
  const classes = useStyles();
  const diff = statistics["total_cost_diff"];

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Całkowity koszt
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              PLN {statistics["current"]["total_cost"]}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        {diff > 0 &&
          <Box
            mt={2}
            display="flex"
            alignItems="center"
          >
            <ArrowUpwardIcon className={classes.differenceIconUp}/>
            <Typography
              className={classes.differenceValueUp}
              variant="body2"
            >
              {diff}%
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              Od ostatniego miesiąca
            </Typography>
          </Box>
        }
        {diff < 0 &&
          <Box
            mt={2}
            display="flex"
            alignItems="center"
          >
            <ArrowDownwardIcon className={classes.differenceIconDown}/>
            <Typography
              className={classes.differenceValueDown}
              variant="body2"
            >
              {diff}%
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              Od ostatniego miesiąca
            </Typography>
          </Box>
        }
      </CardContent>
    </Card>
  );
};

TotalCost.propTypes = {
  className: PropTypes.string
};

export default TotalCost;
