import React from 'react';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { CircularProgress, withStyles } from '@material-ui/core';

// Component styles
const styles = theme => {
    return {
        progressWrapper: {
            display: 'flex',
            justifyContent: 'center',
            height: '36px',
        },
    };
};

const Loader = props => {
    const { classes } = props;

    return (
        <div className={classes.progressWrapper}>
            <CircularProgress />
        </div>
    );
};

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);
