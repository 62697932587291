import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import { api } from 'src/api';
import Page from 'src/components/Page';
import {useAuth} from 'src/context/auth';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UserListView = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (user.hasOwnProperty("is_staff") && !user.is_staff) {
      return navigate('404', { replace: true });
    }
    api.get('users/').then(res => {
      setUsers(res.data);
    });
  }, [setUsers, user, navigate]);

  return (
    <Page
      className={classes.root}
      title="Użytkownicy"
    >
      <Container maxWidth={false}>
        <Toolbar setSearchQuery={setSearchQuery}/>
        <Box mt={3}>
          <Results
            users={
              users.filter(user =>
                user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                user.email.toLowerCase().includes(searchQuery.toLowerCase())
              )
            }
          />
        </Box>
      </Container>
    </Page>
  );
};

export default UserListView;
