import {Button, Grid, makeStyles} from "@material-ui/core";
import React, {useState} from "react";
import {api} from "../../../api";
import Alert from "@material-ui/lab/Alert";


const OrderForm = ({ticket, fetchOrders}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    if (ticket === null) {
        return (
            <Grid
                container
                item
            ></Grid>
        )
    }
    const can_add_order = ticket.service_type === "warranty" || ticket.is_quotation_accepted;

    return (
        <Grid
            container
            item
            key={"order_form"}
            spacing={2}
            xs={12}
        >
            <Grid
                item
                xs={6}
            >
                {(!can_add_order && ticket.is_quotation_accepted === null) && (
                    <Alert severity="warning">Nie można dodać zamówienia, ponieważ wycena nie została jeszcze
                        zaakceptowana</Alert>
                )}
                {(!can_add_order && ticket.is_quotation_accepted === false) && (
                    <Alert severity="error">Nie można dodać zamówienia, ponieważ wycena została odrzucona</Alert>
                )}
                {(ticket.is_quotation_accepted === true) && (
                    <Alert severity="success">Wycena została zaakceptowana przez zgłaszającego</Alert>
                )}
                {ticket.service_type === "warranty" && (
                    <Alert severity="info">Zamówienie gwarancyjne</Alert>
                )}
            </Grid>
            <Grid
                item
                xs={6}
            >
                <Button
                    color="primary"
                    disabled={!can_add_order || isSubmitting}
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() => {
                        setIsSubmitting(true);
                        api.post(`ticket_orders/?ticket=${ticket.id}`, {
                            ticket: ticket.id,
                            direction: "to_client"
                        }).then(() => {
                            fetchOrders();
                            setIsSubmitting(false);
                        });
                    }}
                >
                    Stwórz nowe zamówienie
                </Button>
            </Grid>
            <Grid
                item
                xs={6}
            >
            </Grid>
            <Grid
                item
                xs={6}
            >
                <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() => {
                        setIsSubmitting(true);
                        api.post(`ticket_orders/?ticket=${ticket.id}`, {
                            ticket: ticket.id,
                            direction: "from_client"
                        }).then(() => {
                            fetchOrders();
                            setIsSubmitting(false);
                        });
                    }}
                >
                    Zamówienie sprzętu od klienta
                </Button>
            </Grid>
        </Grid>
    );
}


OrderForm.propTypes = {};

OrderForm.defaultProps = {};

export default OrderForm;
