import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import {exportFile} from "src/api";
import {Tooltip} from 'src/components';
import {Download} from "react-feather";


const TicketSummary = ({ className, tickets, startDate, endDate, ...rest }) => {
  const exportTicketSummary = () => {
    exportFile("tickets/statistics/export/tickets", {startDate, endDate})
  }
  return (
    <Card {...rest}>
      <CardHeader
        title="Podsumowanie zgłoszeń"
        action={
          <IconButton aria-label="settings">
              <Tooltip title="Eksport">
                <Download onClick={exportTicketSummary} />
              </Tooltip>
          </IconButton>
        }
      />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            {tickets.header.map((title, idx) => (
              <TableCell key={`ticket-header-${idx}`}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.data.map((row, idx) => (
            <TableRow
              hover
              key={`ticket-row-${idx}`}
            >
              {row.map((cell, jdx) => (
                <TableCell key={`ticket-cell-${idx}-${jdx}`}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

TicketSummary.propTypes = {
  className: PropTypes.string
};

export default TicketSummary;
