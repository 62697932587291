import React from "react";
import {User} from "react-feather";
import ClickableRichTooltip from "../../../components/ClickableRichTooltip";
import ApplicantTable from "../ApplicantTable";
import {IconButton} from "@material-ui/core";
import {Tooltip} from 'src/components';


const ApplicantTooltip = ({ applicant }) => {
    return (
        <ClickableRichTooltip
            content={
                <ApplicantTable
                    applicant={applicant}
                />
            }
            noSaveButton={true}
            placement="bottom"
        >
            <Tooltip title="Zgłaszający">
                <IconButton style={{padding: 0}}>
                    <User />
                </IconButton>
            </Tooltip>
        </ClickableRichTooltip>
    );
}

export default ApplicantTooltip;
