import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import {exportFile} from "src/api";
import {Tooltip} from 'src/components';
import {Download} from "react-feather";


const ServiceTechnicianSummary = ({ className, serviceTechnicians, startDate, endDate, ...rest }) => {
  const exportServiceTechnicianSummary = () => {
    exportFile("tickets/statistics/export/service_technicians", {startDate, endDate})
  }
  return (
    <Card {...rest}>
      <CardHeader
        title="Podsumowanie serwisantów"
        action={
          <IconButton aria-label="settings">
              <Tooltip title="Eksport">
                <Download onClick={exportServiceTechnicianSummary} />
              </Tooltip>
          </IconButton>
        }
      />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            {serviceTechnicians.header.map((title, idx) => (
              <TableCell key={`service-technician-header-${idx}`}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceTechnicians.data.map((row, idx) => (
            <TableRow
              hover
              key={`service-technician-row-${idx}`}
            >
              {row.map((cell, jdx) => (
                <TableCell key={`service-technician-cell-${idx}-${jdx}`}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

ServiceTechnicianSummary.propTypes = {
  className: PropTypes.string
};

export default ServiceTechnicianSummary;
