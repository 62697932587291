import {Box, Button, Divider, Table, TableBody, TableCell, TableFooter, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {api} from "../../../api";
import {useParams} from "react-router-dom";
import {Trash2} from "react-feather";


const UsedPartsTable = ({usedParts, fetchUsedParts, noAction}) => {
    const {ticketId} = useParams();
    if (usedParts.length === 0) {
        return <></>
    }
    return (
        <Box my={2}>
            <Divider/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Nazwa
                        </TableCell>
                        <TableCell>
                            Liczba
                        </TableCell>
                        <TableCell>
                            Cena
                        </TableCell>
                        <TableCell>
                            Opis
                        </TableCell>
                        {
                            !noAction &&
                            <TableCell>
                                Akcje
                            </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {usedParts.map((usedPart, index) =>
                        <TableRow
                            hover
                            key={`usedPart${index}`}
                        >
                            <TableCell>
                                {usedPart.name}
                            </TableCell>
                            <TableCell>
                                {usedPart.quantity}
                            </TableCell>
                            <TableCell>
                                {usedPart.price}
                            </TableCell>
                            <TableCell>
                                {usedPart.description}
                            </TableCell>
                            {
                                !noAction &&
                                <TableCell>
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        startIcon={<Trash2/>}
                                        onClick={() => {
                                            api.delete(`ticket_parts/${usedPart.id}/?ticket=${ticketId}`,).then(fetchUsedParts);
                                        }}
                                    >
                                        Usuń
                                    </Button>
                                </TableCell>
                            }
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell>
                            Suma
                        </TableCell>
                        <TableCell>
                            {usedParts.reduce((a, b) => a + b.quantity, 0)}
                        </TableCell>
                        <TableCell>
                            {usedParts.reduce((a, b) => a + (parseFloat(b.price) * b.quantity), 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        {
                            !noAction &&
                            <TableCell>
                            </TableCell>
                        }
                    </TableRow>
                </TableFooter>
            </Table>
        </Box>
    );
}

UsedPartsTable.propTypes = {};

UsedPartsTable.defaultProps = {};

export default UsedPartsTable;