import axios from 'axios';
import { API_URL } from '../constants';
import fileDownload from "js-file-download";

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Authorization': localStorage.getItem('access_token') ? 'JWT ' + localStorage.getItem('access_token') : null,
    // 'Content-Type': 'application/json',
    'accept': 'application/json'
  }
});

export const exportFile = (url, params) => {
  api.get(url, {responseType: 'blob', params: params}).then(res => {
    fileDownload(res.data, res.headers['filename']);
  })
}

api.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    // Prevent infinite loops early
    if (error.response.status === 401 && originalRequest.url === `${API_URL}/users/token/refresh/`) {
      window.location.href = '/login/';
      return Promise.reject(error);
    }

    if (
      (error.response.data.code === 'token_not_valid' || error.response.data instanceof Blob)
      && error.response.status === 401
    ) {

      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return api
            .post('/users/token/refresh/', {refresh: refreshToken})
            .then((response) => {

              localStorage.setItem('access_token', response.data.access);
              localStorage.setItem('refresh_token', response.data.refresh);

              api.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
              originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;

              return api(originalRequest);
            })
            .catch(err => {
              console.log(err)
            });
        } else {
          console.log('Refresh token is expired', tokenParts.exp, now);
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          window.location.href = '/login/';
        }
      } else {
        console.log('Refresh token not available.')
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.href = '/login/';
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);
