import React from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {Trash2} from "react-feather";
import {Tooltip} from "./index";
import {api} from "../api";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  light: {
    backgroundColor: theme.palette.background.light
  },
  dark: {
    backgroundColor: "#D0D0D0"
  },
  fonts: {
    fontWeight: "bold"
  },
  inline: {
    display: "inline"
  },
  content: {
    whiteSpace: "pre-wrap"
  }
}));

const Comments = ({comments, ticket, fetchTicketMessages}) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {comments.map((comment, index) => {
        return (
          <React.Fragment key={"fragment_comment_" + index}>
            <ListItem key={"comment_" + index} alignItems="flex-start" className={
              comment?.created_by?.id ? classes.dark : classes.light
            }>
              <ListItemText
                key={"comment_item_text_" + index}
                primary={
                  <Typography className={classes.fonts}>
                    {comment?.created_by?.first_name || ticket?.applicant?.first_name || "-"}
                  </Typography>
                }
                secondary={
                  <>
                    <div className={classes.content}>
                      {comment.content}
                    </div>
                    {moment(comment?.timestamp).format('DD-MM-YYYY HH:mm:ss')}
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="Usuń komentarz">
                  <IconButton
                    color="primary"
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                        api.delete(`ticket_messages/${comment.id}/?ticket=${ticket?.id}`).then(fetchTicketMessages);
                    }}
                  >
                      <Trash2/>
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        );
      })}
    </List>
  )
}

export default Comments;
