import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";


const ApplicantTable = ({ applicant }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Imię i nazwisko na fakturze
                    </TableCell>
                    <TableCell>
                        Nazwa firmy
                    </TableCell>
                    <TableCell>
                        NIP
                    </TableCell>
                    <TableCell>
                        Ulica i numer
                    </TableCell>
                    <TableCell>
                        Kod pocztowy
                    </TableCell>
                    <TableCell>
                        Miejscowość
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow
                    hover
                    key={applicant.id}
                >
                    <TableCell>
                        {applicant.invoice_fullname}
                    </TableCell>
                    <TableCell>
                        {applicant.invoice_company}
                    </TableCell>
                    <TableCell>
                        {applicant.invoice_nip}
                    </TableCell>
                    <TableCell>
                        {applicant.invoice_address}
                    </TableCell>
                    <TableCell>
                        {applicant.invoice_postcode}
                    </TableCell>
                    <TableCell>
                        {applicant.invoice_city}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default ApplicantTable;
