import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    DialogContent,
    DialogTitle, Grid,
    makeStyles,
    MenuItem,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import Page from 'src/components/Page';
import {api} from 'src/api';
import {Loader} from 'src/components';
import {Link, useParams} from "react-router-dom";
import {Download, Edit3, HelpCircle, Save, Send} from "react-feather";
import {ArrowBack} from "@material-ui/icons";
import UsedCheckListElementForm from "../ProjectListView/UsedCheckListElementForm";
import UsedCheckListElementTable from "../ProjectListView/UsedCheckListElementTable";
import UsedPartForm from "../ProjectListView/UsedPartForm";
import UsedPartsTable from "../ProjectListView/UsedPartsTable";
import OrderForm from "../ProjectListView/OrderForm";
import OrdersTable from "../ProjectListView/OrdersTable";
import {API_URL} from "../../../constants";
import {Formik} from "formik";
import {useAuth} from "../../../context/auth";
import ApplicantTable from "../ApplicantTable";
import TicketTable from "../TicketTable";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    spacer: {
        flexGrow: 1,
    },
    whiteButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#FFFFFF',
    },
    img: {
        textAlign: 'center',
    },
    textTop: {
        verticalAlign: "top"
    }
}));

const TicketDetailsView = () => {
    const {ticketId} = useParams();
    const classes = useStyles();
    const {user} = useAuth();
    const [ticket, setTicket] = useState(null);
    const [serviceType, setServiceType] = useState("");
    const [repairDuration, setRepairDuration] = useState("");
    const [notes, setNotes] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [report, setReport] = useState("");
    const [parts, setParts] = useState([]);
    const [checkListElements, setCheckListElements] = useState([])
    const [contentTemplates, setContentTemplates] = useState([])
    const [usedParts, setUsedParts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [usedCheckListElements, setUsedCheckListElements] = useState([]);
    const [isLoadingCheckListElements, setIsLoadingCheckListElements] = useState(true);
    const [isLoadingTicket, setIsLoadingTicket] = useState(true);
    const [isLoadingParts, setIsLoadingParts] = useState(true);
    const [isLoadingOrders, setIsLoadingOrders] = useState(true);
    const partsCost = usedParts.reduce((a, b) => a + parseFloat(b.price) * b.quantity, 0).toFixed(2);
    const [openHelperModal, setOpenHelperModal] = useState(null);

    const manager_user = user?.is_staff || ["Admin", "Manager"].indexOf(user?.role) !== -1;
    const service_user = manager_user || user?.role === "Serwisant";

    const fetchContentTemplates = () => {
        api.get(`content_templates/?category_or_none=${ticket?.category?.id || ''}`).then(res => {
            setContentTemplates(res.data);
        });
    }

    useEffect(fetchContentTemplates, [ticket]);

    const fetchTicket = () => {
        api.get(`tickets/${ticketId}/`).then(res => {
            setTicket(res.data);
            setServiceType(res.data?.service_type);
            setRepairDuration(res.data?.repair_duration);
            setNotes(res.data?.notes);
            setDiagnosis(res.data?.diagnosis);
            setReport(res.data?.report);
            setIsLoadingTicket(false);
        });
    }

    useEffect(fetchTicket, [ticketId]);

    const fetchCheckListElements = () => {
        if (!ticket?.category) return;

        api.get(`check_list_elements/?ticket=${ticketId}&category=${ticket?.category?.id}`).then(res => {
            setCheckListElements(res.data);
            setIsLoadingCheckListElements(false);
        });
    }

    useEffect(fetchCheckListElements, [ticket, ticketId]);

    const fetchParts = () => {
        if (!ticket?.category) return;

        api.get(`parts/?ticket=${ticketId}&category=${ticket?.category?.id}`).then(res => {
            setParts(res.data);
            setIsLoadingParts(false);
        });
    }

    useEffect(fetchParts, [ticket, ticketId]);

    const fetchUsedParts = () => {
        api.get(`ticket_parts/?ticket=${ticketId}`).then(res => {
            setUsedParts(res.data);
            setIsLoadingParts(false);
        });
    }

    const exportReport = () => {
        window.open(`${API_URL}/tickets/${ticketId}/report`, '_self');
    }

    const sendPaidRepairEmail = () => {
        api.post(`${API_URL}/tickets/${ticketId}/send_paid_repair_mail/`).then(res => {
            fetchTicket();
        });
    }

    const sendWarrantyRepairEmail = () => {
        api.post(`${API_URL}/tickets/${ticketId}/send_warranty_repair_mail/`).then(res => {
            fetchTicket();
        });
    }

    useEffect(fetchUsedParts, [ticketId]);

    const fetchOrders = () => {
        api.get(`ticket_orders/?ticket=${ticketId}`).then(res => {
            setOrders(res.data);
            setIsLoadingOrders(false);
        });
    }

    useEffect(fetchOrders, [ticketId]);

    const fetchUsedCheckListElements = () => {
        api.get(`ticket_check_list_elements/?ticket=${ticketId}`).then(res => {
            setUsedCheckListElements(res.data);
            setIsLoadingCheckListElements(false);
        });
    }
    useEffect(fetchUsedCheckListElements, [ticketId]);

    return (
        <Page
            className={classes.root}
            title="Raport"
        >
            <Container maxWidth={false}>
                <Box display="flex">
                    <Button
                        className={classes.whiteButton}
                        startIcon={<ArrowBack/>}
                        variant="contained"
                        component={Link} to={`/app/ticket/details/${ticketId}`}
                    >
                        Wstecz
                    </Button>
                    <span className={classes.spacer}/>
                    {
                        partsCost > 0 && (!ticket?.is_repair_email_sent || ticket.is_quotation_accepted === false) &&
                        ticket?.service_type === "paid" &&
                        <Button
                            color="primary"
                            startIcon={<Send/>}
                            variant="contained"
                            onClick={sendPaidRepairEmail}
                        >
                            Poinformuj klienta o naprawie płatnej
                        </Button>
                    }
                    {
                        usedParts.length > 0 && !ticket?.is_repair_email_sent &&
                        ticket?.service_type === "warranty" &&
                        <Button
                            color="primary"
                            startIcon={<Send/>}
                            variant="contained"
                            onClick={sendWarrantyRepairEmail}
                        >
                            Poinformuj klienta o naprawie gwarancyjnej
                        </Button>
                    }
                    {
                        ticket?.is_repair_email_sent &&
                        <Button
                            color="primary"
                            startIcon={<Edit3/>}
                            variant="contained"
                            component={Link} to={`/ticket/repair/${ticketId}`}
                        >
                            Szczegóły naprawy
                        </Button>
                    }
                    <Box ml={1}>
                        <Button
                            color="primary"
                            startIcon={<Download/>}
                            variant="contained"
                            onClick={exportReport}
                        >
                            Pobierz
                        </Button>
                    </Box>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Szczegóły
                            </Typography>
                            {isLoadingTicket && <Loader/>}
                            {!isLoadingTicket && <TicketTable ticket={ticket}/>}
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Zgłaszający
                            </Typography>
                            {isLoadingTicket && <Loader/>}
                            {!isLoadingTicket &&
                                <ApplicantTable applicant={ticket?.applicant}/>
                            }
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Check Lista
                            </Typography>
                            {isLoadingCheckListElements && <Loader/>}
                            {!isLoadingCheckListElements &&
                                <>
                                    <UsedCheckListElementForm key="addUsedCheckListElement"
                                                              checkListElements={checkListElements}
                                                              fetchUsedCheckListElements={fetchUsedCheckListElements}/>
                                    <UsedCheckListElementTable usedCheckListElements={usedCheckListElements}
                                                               fetchUsedCheckListElements={fetchUsedCheckListElements}/>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Raport
                            </Typography>
                            {isLoadingTicket && <Loader/>}
                            {!isLoadingTicket &&
                                <Formik
                                    initialValues={{
                                        serviceType: serviceType,
                                        paymentMethod: ticket.payment_method,
                                        repairDuration: repairDuration,
                                        notes: notes,
                                        diagnosis: diagnosis,
                                        report: report,
                                    }}
                                    onSubmit={(values, {setSubmitting}) => {
                                        let duration = values.repairDuration;
                                        if (duration && duration.length === 5) {
                                            duration = `${duration}:00`
                                        }
                                        api.patch(`tickets/${ticketId}/`, {
                                            service_type: values.serviceType,
                                            repair_duration: duration,
                                            notes: values.notes,
                                            diagnosis: values.diagnosis,
                                            report: values.report,
                                        }).then((res) => {
                                            setTicket(res.data)
                                            setServiceType(values.serviceType);
                                            setRepairDuration(values.repairDuration);
                                            setNotes(values.notes);
                                            setDiagnosis(values.diagnosis);
                                            setReport(values.report)
                                            setSubmitting(false);
                                        });
                                    }}
                                >
                                    {({
                                          errors,
                                          handleChange,
                                          handleSubmit,
                                          isSubmitting,
                                          touched,
                                          values
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box p={1}>
                                                <TextField
                                                    key="serviceType"
                                                    error={Boolean(touched.serviceType && errors.serviceType)}
                                                    fullWidth
                                                    select
                                                    helperText={touched.serviceType && errors.serviceType}
                                                    name="serviceType"
                                                    label="Typ usługi"
                                                    input={<OutlinedInput label="Typ usługi"/>}
                                                    onChange={handleChange}
                                                    value={values.serviceType}
                                                >
                                                    <MenuItem value={"warranty"}>{"gwarancyjna"}</MenuItem>
                                                    <MenuItem value={"paid"}>{"płatna"}</MenuItem>
                                                </TextField>
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    disabled
                                                    key="paymentMethod"
                                                    fullWidth
                                                    select
                                                    name="paymentMethod"
                                                    label="Metoda płatności"
                                                    input={<OutlinedInput label="Metoda płatności"/>}
                                                    onChange={handleChange}
                                                    value={values.paymentMethod}
                                                >
                                                    <MenuItem value={"cash"}>{"Za pobraniem"}</MenuItem>
                                                    <MenuItem value={"online"}>{"Przelewy24"}</MenuItem>
                                                </TextField>
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    key="repairDuration"
                                                    error={Boolean(touched.repairDuration && errors.repairDuration)}
                                                    fullWidth
                                                    helperText={touched.repairDuration && errors.repairDuration}
                                                    name="repairDuration"
                                                    type="time"
                                                    label="Czas naprawy"
                                                    input={<OutlinedInput label="Czas naprawy"/>}
                                                    onChange={handleChange}
                                                    onClick={event => {
                                                        if (values.repairDuration === null) {
                                                            setRepairDuration("00:00");
                                                            values.repairDuration = "00:00";
                                                        }
                                                    }}
                                                    value={values.repairDuration}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    key="notes"
                                                    error={Boolean(touched.notes && errors.notes)}
                                                    fullWidth
                                                    multiline
                                                    helperText={touched.notes && errors.notes}
                                                    name="notes"
                                                    label="Notatki wewnętrzne"
                                                    input={<OutlinedInput label="Notatki wewnętrzne"/>}
                                                    onChange={handleChange}
                                                    value={values.notes}
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <Grid
                                                    container
                                                    key={"diagnosis_content_template"}
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12 - 2 * service_user}>
                                                        <TextField
                                                            key="diagnosis"
                                                            error={Boolean(touched.diagnosis && errors.diagnosis)}
                                                            fullWidth
                                                            multiline
                                                            helperText={touched.diagnosis && errors.diagnosis}
                                                            name="diagnosis"
                                                            label="Diagnoza"
                                                            input={<OutlinedInput label="Diagnoza"/>}
                                                            onChange={handleChange}
                                                            value={values.diagnosis}
                                                        />
                                                    </Grid>
                                                    {
                                                        service_user &&
                                                        <Grid item xs={2}>
                                                            <Button
                                                                color="primary"
                                                                fullWidth
                                                                onClick={() => setOpenHelperModal({
                                                                    values: values,
                                                                    field: "diagnosis",
                                                                    fieldName: "Diagnoza"
                                                                })}
                                                                variant="contained"
                                                                startIcon={<HelpCircle/>}
                                                            >
                                                                Szablon treści
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Box>
                                            <Box p={1}>
                                                <Grid
                                                    container
                                                    key={"report_content_template"}
                                                    spacing={0}
                                                >
                                                    <Grid item xs={12 - 2 * service_user}>
                                                        <TextField
                                                            key="report"
                                                            error={Boolean(touched.report && errors.report)}
                                                            fullWidth
                                                            multiline
                                                            helperText={touched.report && errors.report}
                                                            name="report"
                                                            label="Raport z wykonanych czynności, zalecenia"
                                                            input={<OutlinedInput
                                                                label="Raport z wykonanych czynności, zalecenia"/>}
                                                            onChange={handleChange}
                                                            value={values.report}
                                                        />
                                                    </Grid>
                                                    {
                                                        service_user &&
                                                        <Grid item xs={2}>
                                                            <Button
                                                                color="primary"
                                                                fullWidth
                                                                onClick={() => setOpenHelperModal({
                                                                    values: values,
                                                                    field: "report",
                                                                    fieldName: "Raport z wykonanych czynności, zalecenia"
                                                                })}
                                                                variant="contained"
                                                                startIcon={<HelpCircle/>}
                                                            >
                                                                Szablon treści
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Box>
                                            <Box p={1}>
                                                <Button
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    startIcon={<Save/>}
                                                >
                                                    Zapisz
                                                </Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            }
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Części
                            </Typography>
                            {isLoadingParts && <Loader/>}
                            {!isLoadingParts &&
                                <>
                                    <UsedPartForm key="addUsedPart" parts={parts} fetchUsedParts={fetchUsedParts}/>
                                    <UsedPartsTable usedParts={usedParts} fetchUsedParts={fetchUsedParts}/>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Box>
                <Box mt={3}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                Zamówienia
                            </Typography>
                            {isLoadingOrders && <Loader/>}
                            {!isLoadingOrders &&
                                <>
                                    {
                                        orders &&
                                        <OrdersTable orders={orders} fetchOrders={fetchOrders}/>
                                    }
                                    <OrderForm key="addOrder" ticket={ticket} fetchOrders={fetchOrders}/>
                                </>
                            }
                        </CardContent>
                    </Card>
                </Box>
                {
                    openHelperModal !== null &&
                    service_user &&
                    <Dialog onClose={() => setOpenHelperModal(null)} open={openHelperModal !== null} fullWidth
                            maxWidth="xl">
                        <DialogTitle>
                            Szablon treści dla pola: {openHelperModal?.fieldName}
                        </DialogTitle>
                        <DialogContent>
                            <Box minWidth={1050}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{width: "10%"}}>
                                                Tytuł
                                            </TableCell>
                                            <TableCell style={{width: "70%"}}>
                                                Opis
                                            </TableCell>
                                            <TableCell style={{width: "20%"}}>
                                                Akcje
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {contentTemplates.map((contentTemplate, index) => (
                                            <TableRow
                                                hover
                                                key={"ct" + index}
                                                className={classes.alignTop}
                                            >
                                                <TableCell>
                                                    {contentTemplate?.title}
                                                </TableCell>
                                                <TableCell>
                                                    {contentTemplate?.description}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            openHelperModal.values[openHelperModal.field] = openHelperModal.values[openHelperModal.field] + contentTemplate?.description;
                                                            setOpenHelperModal(null);
                                                        }}
                                                        variant="contained"
                                                        startIcon={<Save/>}
                                                    >
                                                        Wybierz szablon
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </DialogContent>
                    </Dialog>
                }
            </Container>
        </Page>
    );
};

export default TicketDetailsView;
