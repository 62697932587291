import React, {useEffect, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import Page from 'src/components/Page';
import {api} from 'src/api';
import {Search} from 'react-feather';
import {Loader} from 'src/components';
import Results from "./Results";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  projectCard: {
    height: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const ProjectList = () => {
  const classes = useStyles();
  const [defects, setDefects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');

  const fetchCategories = () => {
    api.get('categories/').then(res => {
      setCategories(res.data);
    });
  }

  useEffect(fetchCategories, []);

  const fetchDefects = () => {
    api.get('defects_full/').then(res => {
      setDefects(res.data);
      setIsLoading(false);
    });
  }

  useEffect(fetchDefects, []);

  const search = (defect) => {
    const values = [
      defect.name,
      defect.device?.name,
      defect.device?.category?.name,
    ];

    let ok = false
    for (const value of values) {
      if (value?.toLowerCase().includes(searchQuery)) {
        ok = true;
      }
    }

    if (categoryFilter) {
      ok = ok && categoryFilter === defect?.device?.category?.id;
    }

    return ok;
  }

  return (
    <Page
      className={classes.root}
      title="Usterki"
    >
      <Container maxWidth={false}>
        <Box>
          <Card>
            <CardContent>
              <Box mr={2}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                  >
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon
                                color="action"
                                fontSize="small"
                              >
                                <Search />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}
                        onChange={e => setSearchQuery(e.target.value?.toLowerCase())}
                        placeholder="Wyszukaj usterkę"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <FormControl
                      className={classes.formControl}
                    >
                      <TextField
                        label="Kategoria"
                        labelId="defectCategoriesFilter"
                        name="defectCategoriesFilter"
                        onChange={(e) => setCategoryFilter(e.target.value)}
                        value={categoryFilter}
                        variant="outlined"
                        select
                      >
                        <MenuItem key="empty_defect_status" value="">---</MenuItem>
                        {categories.map(category =>
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt={3}>
          {isLoading && <Loader/>}
          {!isLoading &&
            <Results
              fetchDefects={fetchDefects}
              defects={defects.filter(search)}
            />
          }
        </Box>
      </Container>
    </Page>
  );
};

export default ProjectList;
