import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import {exportFile} from "src/api";
import {Tooltip} from 'src/components';
import {Download} from "react-feather";


const PopularParts = ({ className, parts, startDate, endDate, ...rest }) => {
  const exportParts = () => {
    exportFile("tickets/statistics/export/parts", {startDate, endDate})
  }

  return (
    <Card {...rest}>
      <CardHeader
        title="Najpopularniejsze części"
        action={
          <IconButton aria-label="settings">
            <Tooltip title="Eksport">
              <Download onClick={exportParts} />
            </Tooltip>
          </IconButton>
        }
      />
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Część
            </TableCell>
            <TableCell sortDirection="desc">
              <TableSortLabel
                active
                direction="desc"
              >
                Liczba
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parts.map((part, idx) => (
            <TableRow
              hover
              key={`part-${idx}`}
            >
              <TableCell>
                {part.name}
              </TableCell>
              <TableCell>
                {part.count}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

PopularParts.propTypes = {
  className: PropTypes.string
};

export default PopularParts;
